/* eslint-disable react/prop-types */
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { Box, Text, Flex, FeatherIcon, H2 } from '../../elements'
import { Consumer as TranslationsConsumer } from '../../containers/Translations'
import { breakLongStrings } from '../../utils'
// import { filter } from 'minimatch'

const Description = ({ description, color, title, icon = 'edit-3' }) => {
  const hasDescription =
    description &&
    typeof description === 'object' &&
    !!Object.values(description).filter(d => d).length

  // const desc = typeof description === 'string' ? description : null

  return hasDescription ? (
    <TranslationsConsumer>
      {({ t, currentLocale }) => {
        const hasTranslation = !!(description && description[currentLocale])
        const textToUse = hasTranslation
          ? description[currentLocale]
          : description.en || description.fr || description.nl
        const str = breakLongStrings({ string: textToUse, tag: 'p' })

        return (
          <Box.container {...{}}>
            <Flex
              {...{
                alignItems: 'flex-start',
              }}
            >
              <FeatherIcon
                {...{
                  icon,
                  color,
                  // fill: 'white',
                  size: [3, 3, 4],
                  mr: 1,
                  mt: -1,
                  // m: 'auto',
                  // mx: 1,
                  // mb: 1,
                }}
              />
              <H2
                {...{
                  // fontSize: 1,
                  fontSize: [2, 2, 3],
                  fontWeight: 'regular',
                  color,
                  css: css`
                    text-transform: uppercase;
                  `,
                }}
              >
                {title || t('description')}
                {!hasTranslation && (
                  <React.Fragment>
                    <span>{` - `}</span>
                    <Text
                      {...{
                        as: 'span',
                        fontSize: 1,
                        // fontWeight: 'bold',
                        // m: null,
                        // mb: 2,
                        color,
                        bg: 'textAlt',
                        css: {
                          textTransform: 'none',
                        },
                      }}
                    >
                      {`${t('upcomingTranslation')}`}
                    </Text>
                  </React.Fragment>
                )}
              </H2>
            </Flex>
            <Box
              {...{
                color,
                my: 2,
              }}
            >
              <Text
                {...{
                  dangerouslySetInnerHTML: { __html: str },
                  // as: 'p',
                  // fontSize: 1,
                  fontSize: [1, 1, 2],
                  color: 'text',
                }}
              >
                {/* {str} */}
              </Text>
            </Box>
          </Box.container>
        )
      }}
    </TranslationsConsumer>
  ) : null
}

// Description.propTypes = {
//   description: PropTypes.shape({
//     fr: PropTypes.string.isRequired,
//     en: PropTypes.string.isRequired,
//     nl: PropTypes.string.isRequired,
//   }).isRequired,
// }

export default Description
