// import React from 'react'
import Heading from './Heading'

const { h1: H1, h2: H2, h3: H3, h4: H4, h5: H5, h6: H6 } = Heading

// import StarIcon from 'react-feather/dist/icons/star'
export Box from './Box'
export Flex from './Flex'
export Position from './Position'
export Fixed from './Fixed'
export Card from './Card'
export Paper from './Paper'
export Text from './Text'
export Layout from './Layout'
export Drawer from './Drawer'
export Page from './Page'
export Input from './Input'
export Slider from './Slider'
// export Box from './Box'
export { H1, H2, H3, H4, H5, H6 }
export Breadcrumbs from './Breadcrumbs'
export Truncate from './Truncate'
export Badge from './Badge'
export Image from './Image'
export Hr from './Hr'
export Button from './Button'
export Circle from './Circle'
export Close from './Close'
export Link from './Link'
// export IdealImage from './IdealImage'
export GatsbyImg from './GatsbyImg'
export GatsbyLink from './GatsbyLink'
export FeatherIcon from './FeatherIcon'
export RightDrawer from './RightDrawer'

// export default {
//   // Button,
//   // Text,
//   Drawer,
//   Fixed,
//   Close,
//   Badge,
//   Link,
//   Hr,
//   Card,
//   Circle,
//   Truncate,
//   Position,

//   // FavoriteIcon: StarIcon,

//   Box,
//   Flex,
//   Text,
//   Layout,
//   Page,
//   Input,
//   Heading,
//   H1: Heading.h1,
//   H2: Heading.h2,
//   H3: Heading.h3,
//   H4: Heading.h4,
//   H5: Heading.h5,
//   H6: Heading.h6,
//   Image,
//   Button,
//   // IdealImage,
//   Breadcrumbs,
//   GatsbyImg,
//   GatsbyLink,
//   FeatherIcon,
//   RightDrawer,
// }
