/* eslint-disable no-undef, react/prop-types */
import styled from '@emotion/styled'
import { fontSize } from 'styled-system'
import { Flex } from '@rebass/emotion'

const Layout = styled(Flex)(
  {
    flexGrow: 1,
    minHeight: '100vh',
    flexFlow: 'column',
    // margin: 'auto',
  },
  fontSize,
)

Layout.displayName = 'Layout'
Layout.defaultProps = {
  className: 'layout',
}

export default Layout
