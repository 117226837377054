/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import PropTypes from 'prop-types'

import { Box, Card, FeatherIcon } from '../../elements'
import {
  SectionExhibitors,
  SectionAnimations,
  SectionBoardgames,
  SectionGuests,
} from '..'
import { Consumer as TranslationsConsumer } from '../../containers/Translations'

const ARRAY_LENGTH = 7

const SearchDrawer = ({
  input,
  isFocused,
  handleFocus,

  boardgames,
  exhibitors,
  animations,
  guests,
}) => {
  const boardgamesTen = boardgames.slice(0, ARRAY_LENGTH)
  const exhibitorsTen = exhibitors.slice(0, ARRAY_LENGTH)
  const animationsTen = animations.slice(0, ARRAY_LENGTH)
  const guestsTen = guests.slice(0, ARRAY_LENGTH)

  return (
    isFocused &&
    input && (
      <TranslationsConsumer>
        {({ t }) => (
          <Card
            {...{
              onClick: e => {
                // e.stopPropagation()
              },

              boxShadow: 1,
              my: 2,
              p: 2,
              css: {
                zIndex: 1,
                position: 'absolute',
                top: 36,
                left: 4,
                right: 4,
              },
            }}
          >
            <Box
              {...{
                onClick: () => {
                  handleFocus(false)
                },

                width: 22,
                ml: 'auto',
                css: {
                  cursor: 'pointer',
                },
              }}
            >
              <FeatherIcon
                {...{
                  icon: 'x',
                  color: 'text',
                  size: 3,
                  // mr: 1,
                }}
              />
            </Box>

            <SectionExhibitors
              {...{
                exhibitors: exhibitorsTen,
                t,
                dropStatusCheck: true,
                showDots: exhibitors.length > ARRAY_LENGTH,
              }}
            />
            <SectionAnimations
              {...{
                animations: animationsTen,
                t,
                dropStatusCheck: true,
                showDots: animations.length > ARRAY_LENGTH,
                column: true,
              }}
            />
            <SectionBoardgames
              {...{
                boardgames: boardgamesTen,
                t,
                dropStatusCheck: true,
                showDots: boardgames.length > ARRAY_LENGTH,
                column: true,
              }}
            />
            <SectionGuests
              {...{
                guests: guestsTen,
                t,
                dropStatusCheck: true,
                showDots: guests.length > ARRAY_LENGTH,
                // column: true,
              }}
            />
          </Card>
        )}
      </TranslationsConsumer>
    )
  )
}

SearchDrawer.propTypes = {}

export default SearchDrawer
