const qs = require('qs')

const getYoutubeParamsFromUrl = url => {
  if (!url) {
    return { url: null, id: null }
  }
  const templateStrings = ['https://www.youtube.com/watch', 'https://youtu.be/']

  if (url.match(templateStrings[0])) {
    const { v: id, t } = qs.parse(url.split(templateStrings[0])[1], {
      ignoreQueryPrefix: true,
    })
    const startSeconds = t && +t.split('s')[0]
    return { id, url, opts: { ...(startSeconds && { startSeconds }) } }
  }
  if (url.match(templateStrings[1])) {
    return {
      id: url.split(templateStrings[1])[1],
      url,
    }
  }

  // we suppose a short string is just the video ID
  if (url.length < 16) {
    return {
      id: url,
      url: `https://youtu.be/${url}`,
    }
  }

  // eslint-disable-next-line no-console
  console.error(`SOFT ERROR ---- incorrect Youtube URL: ${url}`)

  return {
    id: null,
    url: null,
  }
}

module.exports = getYoutubeParamsFromUrl
