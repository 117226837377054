import styled from '@emotion/styled'
import { textAlign, border, layout, space } from 'styled-system'

import Badge from './Badge'

const Circle = styled(Badge)(textAlign, border, layout, space)

Circle.displayName = 'Circle'
Circle.defaultProps = {
  size: 24,
  align: 'center',
  borderRadius: '100%',
  px: null,
  py: null,
}

export default Circle
