/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import qs from 'qs'
import Youtube from 'react-youtube'
// import { Text } from '../../elements'

const MyYoutube = ({ videoId, width, height, playerParams, className }) => {
  // let src = `https://www.youtube.com/embed/${videoId}`
  // if (playerParams) {
  //   const paramsString = qs.stringify(playerParams)
  //   console.log(paramsString)
  //   src += paramsString
  // }

  //   return (
  //     <iframe width="100%" height="100%" src="https://www.youtube.com/embed/pWjYeZC2iZ8?start=34" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  //   )

  //   return (
  //     <iframe {...{
  //       width: width || '100%',
  //       height: height || '100%',
  //       src,
  //       frameBorder: 0,
  //       allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
  //       allowfullscreen: true,
  //     }} ></iframe>
  // )

  return (
    <Youtube
      {...{
        videoId,
        containerClassName: className,
        opts: {
          width: width || '100%',
          height: height || '100%',
          // height: '390',
          // width: '640',
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            // autoplay: 1,
            ...playerParams,
          },
        },
        // onReady: this._onReady
      }}
    />
  )
}

export default MyYoutube
