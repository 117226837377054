const safeUrl = (unsafeUrl, { protocol = 'http' } = {}) => {
  if (typeof unsafeUrl !== 'string') {
    // throw new Error('Non string value provided as url')
    return ''
  }
  const subStr = unsafeUrl.substring(0, 4).toLowerCase()

  // const low = unsafeUrl.toLowerCase()
  return subStr === 'http' ? unsafeUrl : `${protocol}://${unsafeUrl}`
}

module.exports = safeUrl
