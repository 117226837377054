const canonize = require(`../utils/canonize`)

const index = {
  exhibitors: '/exhibitors/',
  animations: '/animations/',
  tournaments: '/animations/?category=tournaments',
  boardgames: '/boardgames/',
  guests: '/guests/',
  protozone: '/protozone/',
  gamesMarket: '/games-market/',
}
// const exhibitorsCategories = {
//   publishers: '/exhibitors/publishers/',
//   distributors: '/exhibitors/distributors/',
//   shops: '/exhibitors/shops/',
//   associations: '/exhibitors/associations/',
//   gameLibraries: '/exhibitors/game-libraries/',
//   gamesAndSociety: '/exhibitors/games-and-society/',
//   gamesOfTheWorld: '/exhibitors/games-of-the-world/',
// }
// const animationsCategories = {
//   tournaments: '/animations/tournaments/',
//   night: '/animations/night/',
//   murderParties: '/animations/murder-parties/',
//   rolePlayingGames: '/animations/role-playing-games/',
//   escapeGames: '/animations/escape-games/',
//   larp: '/animations/larp/',
//   quidditch: '/animations/quidditch/',
//   lightsaber: '/animations/lightsaber/',
//   freePlay: '/animations/free-play/',
//   conferences: '/animations/conferences/',
//   workshops: '/animations/workshops/',
//   expositions: '/animations/expositions/',
//   others: '/animations/others/',
// }

const routes = {
  index,
  // exhibitorsCategories: {
  //   ...exhibitorsCategories,
  // },
  // animationsCategories: {
  //   ...animationsCategories,
  // },
  ...index,
  // ...exhibitorsCategories,
  // ...animationsCategories,

  animation: name => `/animations/${canonize(name)}/`,
  exhibitor: name => `/exhibitors/${canonize(name)}/`,
  boardgame: name => `/boardgames/${canonize(name)}/`,
  proto: name => `/protozone/${canonize(name)}/`,
  guest: name => `/guests/${canonize(name)}/`,
}

// export default routes
module.exports = routes
