/* eslint-disable no-undef, react/prop-types */
import React, { useMemo } from 'react'
// import PropTypes from 'prop-types'
import createExhibitor from '../../types/exhibitor'
import {
  Image,
  Text,
  GatsbyLink,
  Card,
  Flex,
  FeatherIcon,
  Box,
} from '../../elements'
// import { canonize } from '../../utils'
import { FavoriteSelector } from '../../containers'

const ExhibitorEntry = ({ exhibitor: ex, filterValues, onlyFavorites }) => {
  // const categoryLow = currentCategory && canonize(currentCategory, true)
  const exhibitor = createExhibitor(ex)

  const {
    name: { fr: name },
    nameCanonical,
    relativePath,
    featuredImage,
    isFilteredBy,
  } = exhibitor
  const path = relativePath
  const mainColor = 'exhibitor'

  // FILTERING
  if (filterValues) {
    const fOuts = Object.entries(filterValues).map(([key, fValue]) => {
      const fOut = useMemo(() => isFilteredBy(key, fValue), [fValue])
      return fOut
    })

    const display = !fOuts.reduce((acc, val) => acc || val, false)

    if (!display) return null
  }

  return (
    <FavoriteSelector
      {...{
        name: `exhibitor.${nameCanonical}`,
      }}
    >
      {({ toggleFavorite, isFavorite }) =>
        (!onlyFavorites || (onlyFavorites && isFavorite)) && (
          <Box
            {...{
              width: [1 / 3, 1 / 4, 1 / 5, 1 / 6],
              css: {
                position: 'relative',
                zIndex: 1,
              },
            }}
          >
            <GatsbyLink.Flex
              {...{
                to: path,
                // onClick,

                flexDirection: 'column',
                // justifyContent: 'space-between',
                // FIXME: flexDirection does not get blacklisted here or in component itself
                // blacklist: ['flexDirection'],
                // p: 2,
                mb: 3,
              }}
            >
              <Card
                {...{
                  // width: '100%',
                  // boxShadow: 0,
                  // css: css`flex-grow:1;`,
                  p: 1,
                  m: 1,
                }}
              >
                <Image
                  {...{
                    featuredImage,
                    // src: featuredImage.thumbnails.large.url,
                    width: '100%',
                    height: 90,
                    objectFit: `contain`,
                  }}
                />
              </Card>
              <Text
                {...{
                  className: 'break',
                  fontSize: 1,
                  textAlign: 'center',
                  color: 'text',
                }}
              >
                {name}
              </Text>
            </GatsbyLink.Flex>
            <Flex
              {...{
                width: 7,
                height: 7,
                flexShrink: 0,
                justifyContent: 'center',
                alignItems: 'center',
                css: {
                  cursor: 'pointer',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  xIndex: 10,
                },
                onClick: e => {
                  e.stopPropagation()
                  toggleFavorite()
                },
              }}
            >
              <FeatherIcon
                {...{
                  icon: 'star',
                  stroke: mainColor,
                  fill: isFavorite ? mainColor : '',
                  size: 3,
                }}
              />
            </Flex>
          </Box>
        )
      }
    </FavoriteSelector>
  )
}

// ExhibitorEntry.propTypes = {
//   boardgame: PropTypes.object.isRequired,
// }

export default ExhibitorEntry
