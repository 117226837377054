import React from 'react'

import { Box, Flex } from '../../elements'
import { HeadingPaperSection, AnimationEntry, Dots } from '..'

const helperName = 'animations'

const SectionTypes = ({
  [helperName]: x = [],
  t,
  headingPaperSection,
  dropStatusCheck, // only useful because we index entries for search and don't want to index the 'published' property
  showDots,
  column,
  ...props
}) => {
  const filteredList = x && x.filter(xi => xi.status === 'published')
  const filteredListExist = !!(filteredList && filteredList.length)
  const list = dropStatusCheck ? x : filteredList

  return (
    !!(dropStatusCheck || filteredListExist) && (
      <Box {...props}>
        <HeadingPaperSection
          {...{
            bg: helperName,
            color: 'textAlt',
            ...headingPaperSection,
          }}
        >
          {t(helperName)}
        </HeadingPaperSection>
        <Flex.container
          {...{
            flexWrap: 'wrap',
            ...(column && {
              css: {
                flexDirection: 'column',
                ' > *': {
                  width: '100% !important',
                },
              },
            }),
          }}
        >
          {list.map(y => (
            <AnimationEntry
              {...{
                key: y.id || y.nameCanonical,
                animation: y,
              }}
            />
          ))}
          {showDots && <Dots />}
        </Flex.container>
      </Box>
    )
  )
}

export default SectionTypes
