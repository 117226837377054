const _ = require('lodash')

const routes = require(`../utils/routes`)
const safeUrl = require(`../utils/safeUrl`)
const canonize = require(`../utils/canonize`)
const filterOutByArray = require(`../utils/filterOutByArray`)
const getYoutubeParamsFromUrl = require(`../utils/getYoutubeParamsFromUrl`)

const filterOut = {
  categories: ({ filters, entryParam }) =>
    filterOutByArray({ filters, entryParam, operator: 'or' }),
}

const createExhibitor = ({
  name,
  nameCanonical,
  nameFallback,
  status,
  category,
  bio,
  description,
  website,
  facebook,
  youtubeVideo,
  // more,
  place,
  featuredImage,
  album,
  animations,
  boardgames,
  guests,
  // ----------------------------
  Name,
  NameEN,
  NameFR,
  NameNL,
  Status,
  Category,
  BioEN,
  BioFR,
  BioNL,
  DescriptionEN,
  DescriptionFR,
  DescriptionNL,
  Website,
  Facebook,
  YoutubeVideo,
  // More,
  Place,

  Logo: [Logo] = [],
  Album,

  Animations,
  Boardgames,
  Guests,
  // Exhibitors___NODE,
  // Authors___NODE,
  // Illustrators___NODE,
  // Tournaments___NODE,
  // AuthorOf___NODE,
  // IllustratorOf___NODE,

  // // Translations
  // identifier,
  // English,
  // French,
  // Dutch,
  // --------------------
  imageUrl,
}) => {
  const nameOk = name || {
    en: Name || NameEN,
    fr: Name || NameFR,
    nl: Name || NameNL,
  }
  const nameFallbackOk = nameFallback || nameOk
  const defaultName = nameOk.en || nameOk.fr || nameOk.nl
  const nameCanonicalOk = nameCanonical || canonize(defaultName)
  const cat = category || _.camelCase(Category)
  const youtubeVideoUrl =
    youtubeVideo ||
    getYoutubeParamsFromUrl(safeUrl(YoutubeVideo, { protocol: 'https' }))

  const exhibitor = {
    name: nameOk,
    nameFallback: nameFallbackOk,
    nameCanonical: nameCanonicalOk,
    status: status || Status,
    category: cat,
    categories: [cat],
    bio: bio || {
      en: BioEN,
      fr: BioFR,
      nl: BioNL,
    },
    description: description || {
      en: DescriptionEN,
      fr: DescriptionFR,
      nl: DescriptionNL,
    },
    website: website || safeUrl(Website),
    facebook: facebook || safeUrl(Facebook, { protocol: 'https' }),
    // youtubeVideoUrl,
    youtubeVideo: youtubeVideoUrl,
    // more: more || {
    //   en: More,
    //   fr: More,
    //   nl: More,
    // },
    place: place || Place,
    featuredImage:
      featuredImage ||
      Logo ||
      (imageUrl && {
        thumbnails: { large: { url: imageUrl } },
      }),
    album: album || Album || [],
    animationsIds: Animations,
    boardgamesIds: Boardgames,
    guestsIds: Guests,
    animations: animations || Animations,
    boardgames: boardgames || Boardgames,
    guests: guests || Guests,
    // ---------------------
    relativePath: routes.exhibitor(nameCanonicalOk),
  }

  const isFilteredBy = (property, filterValue) => {
    return filterOut[property]({
      filters: filterValue,
      entryParam: exhibitor[property],
    })
  }

  return Object.freeze({ ...exhibitor, isFilteredBy })
}

module.exports = createExhibitor
