import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'

import { Flex, Box, H2, FeatherIcon, Text } from '../../elements'
import { Consumer as TranslationsConsumer } from '../../containers/Translations'

const TournamentPrizes = ({ prizes, color }) => {
  const hasPrizes =
    prizes &&
    typeof prizes === 'object' &&
    !!Object.values(prizes).filter(d => d).length

  if (!prizes) return null

  return hasPrizes ? (
    <TranslationsConsumer>
      {({ t, currentLocale }) => {
        const hasTranslation = !!(prizes && prizes[currentLocale])
        const textToUse = hasTranslation
          ? prizes[currentLocale]
          : prizes.en || prizes.fr || prizes.nl

        const prizesArray = textToUse.split('\n')

        return (
          <Box.container {...{}}>
            <Flex
              {...{
                alignItems: 'flex-start',
              }}
            >
              <FeatherIcon
                {...{
                  icon: 'gift',
                  color,
                  // fill: 'white',
                  size: [3, 3, 4],
                  mr: 1,
                  mt: -1,
                  // m: 'auto',
                  // mx: 1,
                  // mb: 1,
                }}
              />
              <H2
                {...{
                  fontSize: [2, 2, 3],
                  fontWeight: 'regular',
                  color,
                  css: css`
                    text-transform: uppercase;
                  `,
                }}
              >
                {t('tournamentPrizes')}
                {!hasTranslation && (
                  <React.Fragment>
                    <span>{` - `}</span>
                    <Text
                      {...{
                        as: 'span',
                        fontSize: 1,
                        // fontWeight: 'bold',
                        // m: null,
                        // mb: 2,
                        color,
                        bg: 'textAlt',
                        css: {
                          textTransform: 'none',
                        },
                      }}
                    >
                      {`${t('upcomingTranslation')}`}
                    </Text>
                  </React.Fragment>
                )}
              </H2>
            </Flex>
            <Box
              {...{
                as: 'ul',
                color,
                pl: 4,
                my: 2,
              }}
            >
              {prizesArray.map(p => (
                <Box {...{ key: p, as: 'li' }}>
                  <Text {...{ as: 'span', fontSize: [1, 1, 2], color: 'text' }}>
                    {p}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box.container>
        )
      }}
    </TranslationsConsumer>
  ) : null
}

TournamentPrizes.propTypes = {
  prizes: PropTypes.string,
  color: PropTypes.string,
}

TournamentPrizes.defaultProps = {
  prizes: null,
  color: 'tournament',
}

export default TournamentPrizes
