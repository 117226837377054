/* eslint-disable no-undef, react/prop-types */
import { Component } from 'react'

class FavoritesStoreContainer extends Component {
  state = {
    favorites: [],
  }

  componentDidMount() {
    // TODO: Check for availability
    // const storageAvailable = type => {
    //   try {
    //     var storage = window[type],
    //       x = '__storage_test__'
    //     storage.setItem(x, x)
    //     storage.removeItem(x)
    //     return true
    //   } catch (e) {
    //     return (
    //       e instanceof DOMException &&
    //       // everything except Firefox
    //       (e.code === 22 ||
    //         // Firefox
    //         e.code === 1014 ||
    //         // test name field too, because code might not be present
    //         // everything except Firefox
    //         e.name === 'QuotaExceededError' ||
    //         // Firefox
    //         e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
    //       // acknowledge QuotaExceededError only if there's something already stored
    //       storage.length !== 0
    //     )
    //   }
    // }

    this.drawFavoritesFromLocalStorage()
  }

  drawFavoritesFromLocalStorage = () => {
    const favorites =
      localStorage &&
      Object.keys(localStorage).filter(key => key.substring(0, 6) === 'isFav:')
    const favoritesFormatted = favorites.map(f => {
      // const fav = f.substring(6)
      const fav = f.split('isFav:')[1]
      let family = ''
      let nameCanonical = ''
      const families = ['exhibitor', 'animation', 'boardgame', 'guest']
      families.forEach(fam => {
        const re = new RegExp(`${fam}\.`, 'i')
        if (fav.match(re)) {
          family = fam
          // eslint-disable-next-line prefer-destructuring
          nameCanonical = fav.split(`${fam}.`)[1]
        }
      })

      return { family, nameCanonical }
    })

    this.setState({ favorites: favoritesFormatted })
  }

  render() {
    const { children } = this.props
    return children({
      favorites: this.state.favorites,
    })
  }
}

export default FavoritesStoreContainer
