/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from '@emotion/core'

import { Flex, Text, FeatherIcon } from '../../elements'
import { BoardgamePictos } from '..'

const BoardgameSummary = ({
  age,
  minPlayers,
  maxPlayers,
  duration,
  color,
  size = 1,
  container,
  ...props
}) => {
  const players = `${minPlayers}-${maxPlayers}`
  const durationStr = `${duration}'`

  return (
    <Flex
      {...{
        // height: 70,
        ...(container && {
          px: [2, 2, 3],
          py: [2, 2, 3],
        }),
        ...props,
      }}
    >
      {[
        { icon: 'age', value: age },
        { icon: 'players', value: players },
        { icon: 'chrono', value: durationStr },
      ].map(({ icon, value }) => (
        <Flex
          {...{
            alignItems: 'center',
            mr: size + 1,
            key: icon,
          }}
        >
          <FeatherIcon
            {...{
              icon,
              color,
              fill: color,
              size,
              // height: 30,
              // width: 30,
              mr: size - 1 > 0 ? size - 1 : 1,
              // mb: 1,
            }}
          />
          <Text
            {...{
              fontSize: size,
              color,
              css: css`
                flex-shrink: 1;
              `,
            }}
          >
            {value}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
  // return (
  //   <Flex {...{}}>
  //     <Image
  //       {...{
  //         featuredImage,
  //         // src: featuredImage.thumbnails.large.url,
  //         w: 100,
  //         height: 100,
  //       }}
  //     />
  //     <Flex
  //       {...{
  //         justifyContent: 'space-around',
  //       }}
  //     >
  //       <Box>{age}</Box>
  //       <Box>{audience}</Box>
  //       <Box>{duration}</Box>
  //     </Flex>
  //   </Flex>
  // )
}

// BoardgameSummary.propTypes = {
//   boardgame: PropTypes.object.isRequired,
// }

export default BoardgameSummary
