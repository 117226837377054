/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import styled from '@emotion/styled'
import { space, color, border } from 'styled-system'

const Hr = styled.hr(space, color, border)

Hr.displayName = 'Hr'

Hr.defaultProps = {
  mx: 0,
  // my: 3,
  my: 2,
  border: 0,
  borderBottom: 1,
  borderColor: 'lightGray',
}

Hr.heading = props => <Hr {...{ ...props, mt: 0 }} />

export default Hr
