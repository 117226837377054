const _ = require('lodash')
const mmt = require('moment-timezone')

const routes = require(`../utils/routes`)
const safeUrl = require(`../utils/safeUrl`)
const canonize = require(`../utils/canonize`)
const filterOutByArray = require(`../utils/filterOutByArray`)
const getYoutubeParamsFromUrl = require(`../utils/getYoutubeParamsFromUrl`)

const filterOut = {
  categories: ({ filters, entryParam }) =>
    filterOutByArray({ filters, entryParam, operator: 'or' }),
  days: ({ filters, entryParam }) =>
    filterOutByArray({ filters, entryParam, operator: 'or' }),
}

const moment = (...args) => mmt(...args).tz('Europe/Brussels')

const createAnimation = ({
  name,
  nameFallback,
  nameCanonical,
  status,
  categories,
  description,
  // pitch,
  website,
  facebook,
  youtubeVideo,
  days: tempDays,
  start: tempStart,
  end: tempEnd,
  more,
  place,
  tournamentPrizes,
  tournamentRules,
  tournamentBookingLink,
  price,
  maxParticipants,
  featuredImage,
  album,
  exhibitors,
  boardgames,
  guests,
  complexSchedule,
  schedule: tempSchedule,
  // ----------------------------
  Name,
  NameFR,
  NameEN = '',
  NameNL = '',
  Status,
  Categories,
  DescriptionEN = '',
  DescriptionFR = '',
  DescriptionNL = '',
  Website,
  Facebook,
  YoutubeVideo,
  Days,
  Start,
  End,
  MoreEN = '',
  MoreFR = '',
  MoreNL = '',
  Place,
  TournamentPrizesEN = '',
  TournamentPrizesFR = '',
  TournamentPrizesNL = '',
  TournamentRules = '',
  TournamentBookingLink,
  Price,
  MaxParticipants,

  FeaturedImage: [FeaturedImage] = [],
  Album,
  Exhibitors,
  Boardgames,
  Guests,
  ComplexSchedule,

  // category,
  // duration,
  // age,
  // audience,
  // accessibility,
  // minPlayers,
  // maxPlayers,

  // logo,
  // picture,

  // Guests___NODE,
  // Boardgames___NODE,
  // Animations___NODE,
  // Exhibitors___NODE,
  // Authors___NODE,
  // Illustrators___NODE,
  // Tournaments___NODE,
  // AuthorOf___NODE,
  // IllustratorOf___NODE,

  // // Translations
  // identifier,
  // English,
  // French,
  // Dutch,
  // ----------------
  imageUrl,
  imageUrlBoardgame,
}) => {
  const nameOk = name || {
    en: NameEN,
    fr: Name || NameFR,
    nl: NameNL,
  }
  const nameFallbackOk = nameFallback || {
    en: NameEN || Name || NameNL,
    fr: Name || NameEN || NameNL,
    nl: NameNL || NameEN || Name,
  }
  const defaultName = nameOk.en || nameOk.fr || nameOk.nl
  const nameCanonicalOk = nameCanonical || canonize(defaultName)

  const youtubeVideoUrl =
    youtubeVideo ||
    getYoutubeParamsFromUrl(safeUrl(YoutubeVideo, { protocol: 'https' }))

  const start = tempStart || Start
  const end = tempEnd || End
  const days = tempDays || Days
  const fridayIsException =
    days && days.indexOf('friday') > -1 && moment(start).hour() < 15
  const sundayIsException =
    days && days.indexOf('sunday') > -1 && moment(end).hour() > 19

  const schedule = () => {
    // if (tempSchedule) return tempSchedule

    const sched = {
      friday: {
        times: {},
        timesArr: [],
        timesString: '',
      },
      saturday: {
        times: {},
        timesArr: [],
        timesString: '',
      },
      sunday: {
        times: {},
        timesArr: [],
        timesString: '',
      },
    }

    if (complexSchedule && !!complexSchedule.length) {
      complexSchedule.forEach(({ Start: st, End: en }) => {
        const day = moment(st)
          .format('dddd')
          .toLowerCase()
        const stTime = moment(st).format('HH:mm')
        const enTime = moment(en).format('HH:mm')
        const stEnTimeString = `${stTime} > ${enTime}`

        sched[day].times[stEnTimeString] = true
        sched[day].timesArr.push(stEnTimeString)

        // inverseSched[stEnTimeString] = inverseSched[stEnTimeString]
        //   ? [...inverseSched[stEnTimeString], day]
        //   : [day]
      })
    } else if (days && !!days.length) {
      Object.keys(sched).forEach(dayFromSched => {
        if (days.indexOf(dayFromSched) > -1) {
          // console.log(dayFromSched)
          let stTime = ''
          let enTime = ''
          if (dayFromSched === 'friday' && fridayIsException) {
            stTime = '15:00'
          } else {
            stTime = moment(start).format('HH:mm')
          }
          if (dayFromSched === 'sunday' && sundayIsException) {
            enTime = '19:00'
          } else {
            enTime = moment(end).format('HH:mm')
          }
          const stEnTimeString = `${stTime} > ${enTime}`

          sched[dayFromSched].times[stEnTimeString] = true
          sched[dayFromSched].timesArr.push(stEnTimeString)

          // inverseSched[stEnTimeString] = inverseSched[stEnTimeString]
          //   ? [...inverseSched[stEnTimeString], dayFromSched]
          //   : [dayFromSched]
        }
      })
    }

    Object.keys(sched).forEach(dayFromSched => {
      sched[dayFromSched].timesString = sched[dayFromSched].timesArr
        .sort()
        .join(' | ')
    })

    const groupem = () => {
      const inverted = {}
      Object.entries(sched).forEach(([dayFromSched, { timesString }]) => {
        if (timesString) {
          inverted[timesString] = inverted[timesString]
            ? [...inverted[timesString], dayFromSched]
            : [dayFromSched]
        }
      })

      return inverted
    }

    sched.groups = groupem()

    return JSON.stringify(sched)
  }

  const animation = {
    name: nameOk,
    nameFallback: nameFallbackOk,
    nameCanonical: nameCanonicalOk,
    status: status || Status,
    categories:
      categories || (Categories && Categories.map(c => _.camelCase(c))) || [],
    description: description || {
      en: DescriptionEN,
      fr: DescriptionFR,
      nl: DescriptionNL,
    },
    website: website || safeUrl(Website),
    facebook: facebook || safeUrl(Facebook, { protocol: 'https' }),
    youtubeVideo: youtubeVideoUrl,
    days,
    start,
    end: end || End,
    more: more || {
      en: MoreEN,
      fr: MoreFR,
      nl: MoreNL,
    },
    place: place || Place,
    tournamentPrizes: tournamentPrizes || {
      en: TournamentPrizesEN,
      fr: TournamentPrizesFR,
      nl: TournamentPrizesNL,
    },
    tournamentRules: tournamentRules || TournamentRules,
    tournamentBookingLink: tournamentBookingLink || TournamentBookingLink,
    price: price || Price,
    maxParticipants: maxParticipants || MaxParticipants,
    featuredImage:
      featuredImage ||
      FeaturedImage ||
      (imageUrl && {
        thumbnails: { large: { url: imageUrl } },
      }),
    album: album || Album || [],
    exhibitors: exhibitors || Exhibitors,
    boardgames:
      boardgames ||
      Boardgames ||
      (imageUrlBoardgame && [
        {
          featuredImage: { thumbnails: { large: { url: imageUrlBoardgame } } },
        },
      ]),
    guests: guests || Guests,
    complexSchedule: complexSchedule || ComplexSchedule,
    // ---------------------
    relativePath: routes.animation(nameCanonicalOk),
    fridayIsException,
    schedule: schedule(),
  }

  const isFilteredBy = (property, filterValue) => {
    return filterOut[property]({
      filters: filterValue,
      entryParam: animation[property],
    })
  }

  return Object.freeze({ ...animation, isFilteredBy })
}

module.exports = createAnimation
