/* eslint-disable no-undef, react/prop-types */
import React, { useMemo } from 'react'
// import PropTypes from 'prop-types'
import createGuest from '../../types/guest'
import {
  Image,
  Text,
  GatsbyLink,
  Card,
  Flex,
  FeatherIcon,
  Box,
} from '../../elements'
// import { canonize } from '../../utils'
import { FavoriteSelector } from '../../containers'

const GuestEntry = ({ guest: gu, filterValues, onlyFavorites }) => {
  // const categoryLow = currentCategory && canonize(currentCategory, true)
  const guest = createGuest(gu)

  const {
    name: { fr: name },
    nameCanonical,
    relativePath,
    featuredImage,
    isFilteredBy,
  } = guest
  const path = relativePath
  const mainColor = 'guest'

  // FILTERING
  if (filterValues) {
    const fOuts = Object.entries(filterValues).map(([key, fValue]) => {
      const fOut = useMemo(() => isFilteredBy(key, fValue), [fValue])
      return fOut
    })

    const display = !fOuts.reduce((acc, val) => acc || val, false)

    if (!display) return null
  }

  return (
    <FavoriteSelector
      {...{
        name: `guest.${nameCanonical}`,
      }}
    >
      {({ toggleFavorite, isFavorite }) =>
        (!onlyFavorites || (onlyFavorites && isFavorite)) && (
          <Box
            {...{
              width: [1 / 3, 1 / 4, 1 / 5, 1 / 6],
            }}
          >
            <GatsbyLink.Flex
              {...{
                to: path,
                // onClick,

                flexDirection: 'column',
                width: 90,
                p: 0,
                // justifyContent: 'space-between',
                // FIXME: flexDirection does not get blacklisted here or in component itself
                // blacklist: ['flexDirection'],
                // p: 2,
                mb: 3,
                mx: 'auto',
              }}
            >
              <Card
                {...{
                  // width: '100%',
                  // boxShadow: 0,
                  // css: css`flex-grow:1;`,
                  p: 0,
                  m: 0,
                  css: {
                    position: 'relative',
                    zIndex: 1,
                  },
                }}
              >
                {featuredImage ? (
                  <Image
                    {...{
                      featuredImage,
                      // src: featuredImage.thumbnails.large.url,
                      // width: '100%',
                      width: 90,
                      height: 90,
                      objectFit: `cover`,
                      borderRadius: '100%',
                    }}
                  />
                ) : (
                  <Flex
                    {...{
                      // bg: 'exhibitor',
                      width: 90,
                      height: 90,
                      px: 0,
                      py: 0,
                      mx: 0,
                      my: 0,
                      css: {
                        justifyContent: 'center',
                        borderRadius: '50%',
                        // height: '50px',
                      },
                    }}
                  >
                    <FeatherIcon
                      {...{
                        icon: 'people',
                        color: 'guest',
                        fill: 'guest',
                        width: '90px',
                        height: '90px',
                        // m: 'auto',
                        // mx: 1,
                        // mb: 1,
                      }}
                    />
                  </Flex>
                )}
                <Flex
                  {...{
                    width: 6,
                    height: 6,
                    flexShrink: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    bg: 'trans.8',
                    css: {
                      cursor: 'pointer',
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      zIndex: 10,
                      borderRadius: '100%',
                    },
                    onClick: e => {
                      e.stopPropagation()
                      toggleFavorite()
                    },
                  }}
                >
                  <FeatherIcon
                    {...{
                      icon: 'star',
                      stroke: mainColor,
                      fill: isFavorite ? mainColor : '',
                      size: 3,
                    }}
                  />
                </Flex>
              </Card>
              <Text
                {...{
                  className: 'break',
                  fontSize: 1,
                  textAlign: 'center',
                  color: 'text',
                }}
              >
                {name}
              </Text>
            </GatsbyLink.Flex>
          </Box>
        )
      }
    </FavoriteSelector>
  )
}

// ExhibitorEntry.propTypes = {
//   boardgame: PropTypes.object.isRequired,
// }

export default GuestEntry
