const _ = require('lodash')

const routes = require(`../utils/routes`)
const canonize = require(`../utils/canonize`)
const filterOutByArray = require(`../utils/filterOutByArray`)

const filterOut = {
  categories: ({ filters, entryParam }) =>
    filterOutByArray({ filters, entryParam, operator: 'or' }),
}

const createGuest = ({
  name,
  nameCanonical,
  nameFallback,
  status,
  description,
  // website,
  // facebook,
  // youtubeVideo,
  featuredImage,
  exhibitors,
  animations,
  authorOf,
  illustratorOf,
  // ----------------------------
  Name,
  // Those 3 names are also useful for search
  NameFR,
  NameEN,
  NameNL,
  Status,
  Picture: [Picture] = [],
  DescriptionEN,
  DescriptionFR,
  DescriptionNL,
  // Website,
  // Facebook,
  // YoutubeVideo,
  Exhibitors,
  Animations,
  AuthorOf,
  IllustratorOf,

  // --------------------
  imageUrl,
}) => {
  const nameOk = name || {
    en: Name || NameEN,
    fr: Name || NameFR,
    nl: Name || NameNL,
  }
  const nameFallbackOk = nameFallback || nameOk
  const defaultName = nameOk.en || nameOk.fr || nameOk.nl
  const nameCanonicalOk = nameCanonical || canonize(defaultName)

  const guest = {
    name: nameOk,
    nameFallback: nameFallbackOk,
    nameCanonical: nameCanonicalOk,
    status: status || Status,
    description: description || {
      en: DescriptionEN,
      fr: DescriptionFR,
      nl: DescriptionNL,
    },
    featuredImage:
      featuredImage ||
      Picture ||
      (imageUrl && {
        thumbnails: { large: { url: imageUrl } },
      }),
    exhibitorsIds: Exhibitors,
    animationsIds: Animations,
    authorOfIds: AuthorOf,
    illustratorOfIds: IllustratorOf,
    exhibitors: exhibitors || Exhibitors,
    animations: animations || Animations,
    authorOf: authorOf || AuthorOf,
    illustratorOf: illustratorOf || IllustratorOf,
    // ---------------------
    relativePath: routes.guest(nameCanonicalOk),
  }

  const isFilteredBy = (property, filterValue) => {
    return filterOut[property]({
      filters: filterValue,
      entryParam: guest[property],
    })
  }

  return Object.freeze({ ...guest, isFilteredBy })
}

module.exports = createGuest
