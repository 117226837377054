import styled from '@emotion/styled'
import { space, width, color, borderRadius } from 'styled-system'

const Slider = styled('input')(width, space, color, borderRadius, props => ({
  display: 'block',
  height: '4px',
  cursor: 'pointer',
  appearance: 'none',
  '&::-webkit-slider-thumb': {
    width: '16px',
    height: '16px',
    backgroundColor: 'currentcolor',
    border: 0,
    borderRadius: '99999px',
    appearance: 'none',
  },
}))

Slider.displayName = 'Slider'

Slider.defaultProps = {
  type: 'range',
  width: '100%',
  mx: 0,
  my: 2,
  color: 'inherit',
  // bg: 'gray',
  borderRadius: 99999,
}

export default Slider
