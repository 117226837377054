/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import system from 'styled-system'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import {
  space,
  color,
  layout,
  typography,
  border,
  shadow,
  flexbox,
} from 'styled-system'

import { Translations } from '../containers'
// const GatsbyLink = system({
//   is: Link,
// })

const GatsbyLinkStyled = styled(Link)(space, color, typography)

GatsbyLinkStyled.defaultProps = {
  fontSize: 1,
}

const GatsbyLink = ({ to, overWriteLocale, ...props }) => (
  <Translations.Consumer>
    {({ currentLocale }) => {
      const compoundedTo =
        !currentLocale || overWriteLocale || currentLocale === 'fr'
          ? to
          : `/${currentLocale}${to}`
      return <GatsbyLinkStyled {...{ to: compoundedTo, ...props }} />
    }}
  </Translations.Consumer>
)

GatsbyLink.Box = styled(GatsbyLink)(
  {
    boxSizing: 'border-box',
  },
  space,
  color,
  layout,
  typography,
  border,
  shadow,
)

GatsbyLink.Flex = styled(GatsbyLink.Box)(
  {
    display: 'flex',
  },
  flexbox,
)

// GatsbyLinkFlex.defaultProps = {
//   blacklist: ['justifyContent', 'alignItems', 'flexDirection', 'flexWrap'],
// }
// export { GatsbyLinkFlex }

// export const GatsbyLinkFlex = styled(GatsbyLinkBox)(props => {
//   // eslint-disable-next-line no-console
//   console.log()
//   return {
//     display: 'flex',
//     flexWrap,
//     flexDirection: props.flexDirection,
//     alignItems,
//     justifyContent,
//   }
// })

// export const GatsbyLinkBox = system(
//   {
//     is: GatsbyLink,
//   },
//   {
//     boxSizing: 'border-box',
//   },
//   'width',
//   // 'space',
//   'fontSize',
//   // 'color',
//   'flex',
//   'order',
//   'alignSelf',
// )

// export const GatsbyLinkFlex = system(
//   {
//     is: GatsbyLinkBox,
//   },
//   { display: 'flex' },
//   'flexWrap',
//   'flexDirection',
//   'alignItems',
//   'justifyContent',
// )

export default GatsbyLink
