/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import { Flex, FeatherIcon, Button } from '../../elements'

const FiltersButtons = ({
  setFilterValue,
  setFiltersOpen,
  resetFilters,
  color,
  onlyFavorites,
  ...props
}) => (
  <Flex
    {...{
      justifyContent: 'space-between',
      maxWidth: 600,
      mx: 'auto',
      py: 2,
    }}
  >
    {[
      { text: 'Reset', onClick: resetFilters },
      {
        text: 'Valider',
        style: {
          color,
          bg: 'textAlt',
        },
        onClick: () => {
          setFiltersOpen(false)
        },
      },
    ].map(({ text, style, onClick }) => (
      <Button
        {...{
          // py: 1,
          bg: 'inherit',
          color: 'inherit',
          border: 2,
          borderColor: 'inherit',
          ...style,
          onClick,
          css: {
            textTransform: 'uppercase',
          },
        }}
      >
        {text}
      </Button>
    ))}
    <Flex
      {...{
        width: 7,
        flexShrink: 0,
        justifyContent: 'center',
        alignItems: 'center',
        bg: onlyFavorites ? 'textAlt' : color,
        border: 2,
        css: {
          cursor: 'pointer',
          borderColor: 'inherit',
          borderRadius: '100%',
        },
        onClick: () => {
          setFilterValue({
            property: 'onlyFavorites',
            val: !onlyFavorites,
          })
        },
      }}
    >
      <FeatherIcon
        {...{
          icon: 'star',
          stroke: onlyFavorites ? color : 'textAlt',
          fill: onlyFavorites ? 'textAlt' : color,
          // fill: color,
          // size: 3,
          size: 5,
          // mr: 1,
          // mb: 1,
          css: {
            // marginLeft: 'auto',
            // marginRight: 'auto',
            padding: 3,
          },
        }}
      />
    </Flex>
  </Flex>
)

export default FiltersButtons
