import React from 'react'

import { Box, Flex } from '../../elements'
import { HeadingPaperSection, BoardgamePreview, Dots } from '..'

const helperName = 'boardgames'

const SectionTypes = ({
  [helperName]: x = [],
  t,
  headingPaperSection,
  dropStatusCheck,
  showDots,
  column,
  title,
  ...props
}) => {
  const filteredList = x && x.filter(xi => xi.status === 'published')
  const filteredListExist = !!(filteredList && filteredList.length)
  const list = dropStatusCheck ? x : filteredList
  return (
    (dropStatusCheck || filteredListExist) && (
      <Box {...props}>
        <HeadingPaperSection
          {...{
            bg: helperName,
            color: 'textAlt',
            ...headingPaperSection,
          }}
        >
          {t(title || helperName)}
        </HeadingPaperSection>
        <Flex.container
          {...{
            flexWrap: 'wrap',
            ...(column && {
              css: {
                flexDirection: 'column',
                ' > *': {
                  width: '100% !important',
                },
              },
            }),
          }}
        >
          {list.map(b => (
            <BoardgamePreview
              {...{
                key: b.id || b.nameCanonical,
                boardgame: b,
              }}
            />
          ))}
          {showDots && <Dots />}
        </Flex.container>
      </Box>
    )
  )
}

export default SectionTypes
