// import components from './components'

// breakpoint values
// any array length works with styled-system
// const breakpoints = ['40em', '52em', '64em']
const breakpoints = ['420px', '700px', '1000px']

// const maxWidths = ['419px', '700px', '1000px']
// const sizes = ['419px', '700px', '1000px']
const sizes = [10, 14, 18, 22, 26, 30, 34, 38, 42, 46]
sizes.small = 419
sizes.medium = 700
sizes.fullWidth = 1000

const site = {
  maxWidth: 1000,
}

const fonts = {
  main: 'nunito, sans-serif',
}

let colors = {
  white: 'rgb(255, 255, 255)',
  burgundi: '#760F0F',
  red: '#A6250C',
  orange: '#C5551D',
  darkGreen: '#163F13',
  midGreen: '#698103',
  lightGreen: '#96AA1C',
  lighterGreen: '#B5BF00',
  sand: '#E9D79B',
  lightGray: 'rgba(0, 0, 0, 0.16)',
  // lightGrey: 'rgba(0, 0, 0, 0.16)',
  yellow: 'rgba(253, 228, 84, 1)',

  // blue: '#2F52A0',
  green: 'rgb(132, 201, 139)',
  // purple: '#5906A0',
  silver: 'rgb(117, 138, 141)',
  pink: 'rgb(243, 113, 96)',
  darkGray: '#7F7F7F',

  denseGreen: 'rgb(116, 137, 41)',
  brown: 'rgb(114, 56, 13)',
  blue: 'rgb(0, 100, 153)',
  night: 'rgb(47, 82, 160)',

  // yellow: 'rgb(245, 189, 24)',
  // lightGray: '#E8E8E8',
  // red: 'rgb(205, 23, 23)',
  // orange: 'rgb(239, 125, 0)',

  trans: [
    'rgba(255, 255, 255, 0)',
    'rgba(255, 255, 255, 0.1)',
    'rgba(255, 255, 255, 0.2)',
    'rgba(255, 255, 255, 0.3)',
    'rgba(255, 255, 255, 0.4)',
    'rgba(255, 255, 255, 0.5)',
    'rgba(255, 255, 255, 0.6)',
    'rgba(255, 255, 255, 0.7)',
    'rgba(255, 255, 255, 0.8)',
    'rgba(255, 255, 255, 0.9)',
    'rgba(255, 255, 255, 1)',
  ],

  // nested objects work as well
  // dark: {
  //   blue: '#058',
  // },
  // arrays can be used for scales of colors
  // gray: ['#333', '#666', '#999', '#ccc', '#eee', '#f6f6f6'],
}
colors = {
  ...colors,
  text: colors.darkGray,
  textAlt: colors.sand,
  background: colors.white,
  bg: colors.white,
  heading: colors.darkGray,
  gamesMarket: colors.lighterGreen,
  protozone: colors.lightGreen,
  tournament: colors.orange,
  tournaments: colors.orange,
  animation: colors.red,
  animations: colors.red,
  exhibitor: colors.burgundi,
  exhibitors: colors.burgundi,
  boardgame: colors.darkGreen,
  boardgames: colors.darkGreen,
  people: colors.midGreen,
  peoples: colors.midGreen,
  guest: colors.midGreen,
  guests: colors.midGreen,

  flag_publishers: colors.denseGreen,
  flag_distributors: colors.denseGreen,
  flag_shops: colors.blue,
  flag_animations: colors.red,
  flag_associations: colors.red,
  flag_gameLibraries: colors.red,
  // flag_conferences: colors.orange,
  flag_gamesOfTheWorld: colors.orange,
  flag_gamesAndSociety: colors.brown,
}

// const colorPalettes = {
//   standard: {
//     background: 'white',
//     header: 'brown',
//     body: 'brown',
//     border: 'brown',
//     link: 'darkGreen',
//     linkHover: 'green',
//   },
//   contrast: {
//     background: 'brown',
//     header: 'white',
//     body: 'white',
//     border: 'white',
//     link: 'green',
//     linkHover: 'darkGreen',
//   },
// }

// space is used for margin and padding scales
// it's recommended to use powers of two to ensure alignment
// when used in nested elements
// numbers are converted to px
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]
// space.container = [8, 8, 16]

// typographic scale
const fontSizes = [10, 14, 18, 22, 26, 30, 34, 38, 42, 46, 50, 54]
// const fontSizes = [
//   '10px',
//   '14px',
//   '18px',
//   '22px',
//   '26px',
//   '30px',
//   '34px',
//   '38px',
//   '42px',
//   '46px',
// ]

// for any scale, either array or objects will work
const lineHeights = [1, 1.125, 1.25, 1.5]

const fontWeights = {
  normal: 400,
  regular: 400,
  bold: 700,
}

const letterSpacings = {
  normal: 'normal',
  caps: '0.25em',
}

// border-radius
const radii = [0, 2, 4, 20, 50, '50%']
radii.smallFeaturedImage = 4
radii.mediumFeaturedImage = 8

const borderWidths = [0, 1, 2]

const borders = [
  'none',
  `solid 1px ${colors.text}`,
  `solid 2px ${colors.text}`,
  `solid 3px ${colors.text}`,
  `solid 4px ${colors.text}`,
  `solid 5px ${colors.text}`,
  `solid 1px ${colors.white}`,
  `solid 2px ${colors.white}`,
  `solid 3px ${colors.white}`,
  `solid 4px ${colors.white}`,
  `solid 5px ${colors.white}`,
]
borders.button = `solid 3px ${colors.text}`
borders.search = `dashed 1px ${colors.sand}`

const shadows = [`0 1px 2px 0 ${colors.text}`, `0 1px 4px 0 ${colors.text}`]
// shadows.mainCatLink = `0 1px 8px 0 ${colors.sand}`
shadows.mainCatLink = `0 -3px 6px 0 rgba(0, 0, 0, 0.16)`
shadows.paper = `0 3px 6px 0 rgba(0, 0, 0, 0.16)`
shadows.drawer = `-3px 0 6px 0 rgba(0, 0, 0, 0.16)`

// export const components = {
//   Box: {
//     p: 30,
//   },
//   Flex: {
//     wrap: true,
//     p: 30,
//   },
//   TemplatePage: {
//     css: css`
//       font-family: 'Muli', sans-serif;
//     `,
//   },
//   Interface: {
//     w: 1,
//   },
//   Layout: {
//     w: 1,
//     flexDirection: `column`,
//     css: css`
//       display: flex;
//       min-height: 100vh;
//     `,
//   },
//   Main: {
//     flexDirection: 'column',
//   },
//   Button: {},
//   Custom: {
//     flexDirection: `column`,
//     // m: [1, 2, 3],
//     // color: `vieuxRose`,
//     // flexDirection: `column`,
//     // css: css`
//     //   color: blue;
//     // `,
//   },
//   Menu: {
//     // flexDirection: `row`,
//     // my: 4,
//     p: 2,
//     // bg: 'blue',
//     // color: 'brown',
//     colors: 'contrast',
//
//     w: 1,
//     alignItems: `center`,
//     justifyContent: `flex-end`,
//
//     css: css`
//       // display: flex;
//       z-index: 100;
//       box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
//       & Li {
//         // background: red;
//       }
//       & > * {
//         flex-shrink: 0;
//       }
//       & *:not(:last-child) {
//         margin-right: 10;
//       }
//     `,
//   },
//   Row: {},
//   Gallery: {
//     wrap: 'wrap',
//   },
// }

// const tatyana = {
//   colors: {
//     // params with names
//     white: 'rgb(255, 255, 255)',
//     brown: '#522D24',
//     green: '#3C8A2E',
//     darkGreen: '#275937',
//     lightGreen: '#13A237',
//     // mapping of names with default values
//     // component usage of default values
//     palettes: {
//       standard: {
//         background: 'white',
//         header: 'brown',
//         body: 'brown',
//         border: 'brown',
//         link: 'darkGreen',
//         linkHover: 'green',
//       },
//       contrast: {
//         background: 'brown',
//         header: 'white',
//         body: 'white',
//         border: 'white',
//         link: 'green',
//         linkHover: 'darkGreen',
//       },
//     },
//   },
// }

const theme = {
  breakpoints,
  site,
  // maxWidths,
  sizes,
  fonts,
  colors,
  // colorPalettes,
  space,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  radii,
  borderWidths,
  borders,
  shadows,
  // components,
}

// // Default values
// const theme = {
//   breakpoints: [32, 48, 64, 80],
//   space: [0, 4, 8, 16, 32, 64, 128],
//   fonts: {
//     sans: 'system-ui, sans-serif',
//     mono: 'Menlo, monospace',
//   },
//   fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
//   fontWeights: {
//     normal: 400,
//     bold: 700,
//   },
//   colors: {
//     black: '#000',
//     white: '#fff',
//     red: '#BF1717',
//     blue: `#2F52A0`,
//     tournament: `blue`,
//     night: `blue`,
//   },
//   radii: [0, 2, 4],
// }

export default theme
