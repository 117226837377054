/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import PropTypes from 'prop-types'
// import { css, jsx } from '@emotion/core'
import { theme } from '../../utils'
import {
  Flex,
  GatsbyLink,
  GatsbyImg,
  Breadcrumbs,
  Box,
  // Button,
  FeatherIcon,
} from '../../elements'

// const svgStyles = css`
//   // opacity: 0.5;
//   transition: opacity 0.15s ease-in;
//   transition: color 0.15s ease-in;
//
//   &:hover {
//     text-decoration: none;
//     box-shadow: none;
//     opacity: 1;
//     transition: opacity 0.15s ease-in;
//   }
// `

const { maxWidth } = theme.site

const Navigation = ({ logo, path, toggleDrawer, windowWidth }) => {
  const showMenuIcon = windowWidth <= maxWidth

  return (
    <Flex {...{ px: [2, 2, 3], my: [3, 3, 3] }}>
      <GatsbyLink to="/">
        <GatsbyImg
          {...{ fixed: logo.childImageSharp.fixed, width: theme.space[5] }}
        />
      </GatsbyLink>
      <Breadcrumbs {...{ path }} />
      {showMenuIcon && (
        <Box
          {...{
            onClick: () => {
              toggleDrawer()
            },

            alignSelf: 'flex-start',
          }}
        >
          <FeatherIcon
            {...{
              icon: 'menu',
              color: 'textAlt',
              size: 3,
              // mr: 1,
            }}
          />
          {/* <Button
        {...{
          color: 'text',
          bg: 'white',
        }}
      >
        {feather('menu', ['20', '20'], svgStyles)}
      </Button> */}
        </Box>
      )}
    </Flex>
  )
}

Navigation.propTypes = {}

export default Navigation
