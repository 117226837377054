/* eslint-disable react/prop-types */
import React from 'react'
import { css } from '@emotion/core'
import { Flex, FeatherIcon, Text } from '../../elements'

const PlaceIcon = ({ place, tPlace, icon, color, fill }) => {
  const place0 = place && place[0]
  const inferredIcon = place0 && (place0.match(/A|B/) ? place0 : 'NUMBER')

  return !tPlace && (icon || inferredIcon) ? (
    <Flex
      {...{
        height: 40,
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
        css: css`
          position: relative;
          flex-shrink: 0;
        `,
      }}
    >
      <FeatherIcon
        {...{
          icon: icon || inferredIcon,

          // stroke: theme.colors.exhibitor,
          color: color || 'textAlt',
          fill: fill || 'textAlt',
          // size: 8,
          height: 40,
          width: 40,
          // mx: 1,
          // mb: 1,
          css: css`
            position: absolute;
            // z-index:-1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          `,
        }}
      />

      <Text
        {...{
          color,
          fontSize: 1,
          fontWeight: 'bold',
          css: css`
            z-index: 1;
          `,
        }}
      >
        {place}
      </Text>
    </Flex>
  ) : (
    <Text
      {...{
        color: 'textAlt',
        fontSize: 1,
        fontWeight: 'bold',
        textAlign: 'right',
        // css: css`z-index:1;`,
      }}
    >
      {tPlace}
    </Text>
  )
}

export default PlaceIcon
