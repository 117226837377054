/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import { css } from '@emotion/core'

import { Text, Flex, Box, FeatherIcon, H1, Paper, Image } from '../../elements'
import { FavoriteSelector, Translations } from '../../containers'

// const placesWords = ['Chapiteau', 'Forum']

const HeadingComplex = ({
  type,
  icon,
  iconColor,
  nameCanonical,
  name,
  place,
  image,
  imageProps,
  boxImageProps,
}) => (
  <Translations.Consumer>
    {({ currentLocale, t }) => (
      <React.Fragment>
        <FavoriteSelector
          {...{
            name: `${
              type === 'tournament' ? 'animation' : type
            }.${nameCanonical}`,
          }}
        >
          {({ toggleFavorite, isFavorite }) => (
            <Paper
              {...{
                bg: type,
                mb: 0,
                p: null,
                py: 2,
              }}
            >
              <Flex.container
                {...{
                  alignItems: 'center',
                }}
              >
                {image && (
                  <Box
                    {...{
                      bg: 'white',
                      size: 70,
                      p: 1,
                      mr: 2,
                      borderRadius: 'smallFeaturedImage',
                      css: {
                        flexShrink: 0,
                      },
                      ...boxImageProps,
                    }}
                  >
                    <Image
                      {...{
                        featuredImage: image,
                        // src: featuredImage.thumbnails.large.url,
                        size: '100%',
                        borderRadius: 'smallFeaturedImage',
                        objectFit: 'contain',
                        ...imageProps,
                      }}
                    />
                  </Box>
                )}
                <H1
                  {...{
                    fontSize: 3,
                    pr: 1,
                    color: 'textAlt',
                    // css: props => {
                    //   const css = props && props.css()
                    //   // eslint-disable-next-line no-console
                    //   console.log(css)
                    //   return `${css} flex-grow:1;`
                    // },
                    css: css`
                      flex-grow: 1;
                      text-transform: uppercase;
                    `,
                  }}
                >
                  {name && name[currentLocale]}
                </H1>

                <Box
                  {...{
                    onClick: () => toggleFavorite(),
                  }}
                >
                  <FeatherIcon
                    {...{
                      icon: 'star',
                      stroke: 'textAlt',
                      fill: isFavorite ? 'textAlt' : '',
                      size: 3,
                      mr: 1,
                      mb: 1,
                    }}
                  />
                </Box>
              </Flex.container>
            </Paper>
          )}
        </FavoriteSelector>
      </React.Fragment>
    )}
  </Translations.Consumer>
)

export default HeadingComplex
