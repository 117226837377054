/* eslint-disable no-unused-vars */
import styled from '@emotion/styled'
import { themeGet } from '@styled-system/theme-get'
import { border, typography, space, color, layout } from 'styled-system'

const Button = styled.button(
  border,
  typography,
  space,
  color,
  layout,
  {
    cursor: 'pointer',
    fontFamily: 'inherit',
    WebkitFontSmoothing: 'antialiased',
    verticalAlign: 'middle',
    textDecoration: 'none',
    appearance: 'none',
    '&:disabled': {
      opacity: 1 / 4,
    },
  },
  props => ({
    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 0 2px ${themeGet(`colors.${props.bg}`, props.bg)(props)}`,
    },
  }),
)

Button.defaultProps = {
  display: 'inline-block',
  textAlign: 'center',
  fontSize: 1,
  fontWeight: 'bold',
  lineHeight: 16 / 14,
  m: 0,
  px: 3,
  py: 2,
  color: 'white',
  bg: 'blue',
  borderRadius: 2,
  border: 0,
}

Button.displayName = 'Button'

export default Button
