const filterOutByArray = ({ filters, entryParam, operator = 'and' }) => {
  // -> returns true if the game does not match all accessibility filters selected

  const entryParamArray = Array.isArray(entryParam)
    ? entryParam
    : entryParam && [entryParam]

  return !filters
    ? false
    : !!Object.entries(filters).reduce((acc, [key, val]) => {
        // early return if operator is AND and it has already been filtered out
        if (operator === 'and' && acc === true) return true

        // val can be false if User selected the filter then deselected it
        if (!val) return acc

        // User activated at least one filter and the game has no accessibility icon
        if (!(entryParamArray && entryParamArray.length)) return true

        // Among the game's
        if (entryParamArray.find(paramItem => paramItem === key)) return false

        if (operator === 'or' && acc === false) return false

        return true
      }, null)
}

module.exports = filterOutByArray
