const happenOnDay = ({
  days,
  day,
  // start,
  // end,
}) => {
  // const dayFromStart = start.substring(8, 10)
  // const dayFromEnd = end.substring(8, 10)
  // const dayOf = {
  //   friday: '24',
  //   saturday: '25',
  //   sunday: '26',
  // }
  if (days && days.indexOf(day) > -1) {
    return true
  }
  // if (dayOf[day] >= dayFromStart && dayOf[day] <= dayFromEnd) {
  //   return true
  // }
  return false
}

module.exports = happenOnDay
