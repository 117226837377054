/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import path from 'path';
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
// import config from '../config';
import { siteConfig } from '../utils'
import { Consumer as TranslationsConsumer } from '../containers/Translations'

// const getSchemaOrgJSONLD = ({
//   isBlogPost,
//   url,
//   title,
//   image,
//   description,
//   datePublished,
// }) => {
//   const schemaOrgJSONLD = [
//     {
//       '@context': 'http://schema.org',
//       '@type': 'WebSite',
//       url,
//       name: title,
//       alternateName: config.title,
//     },
//   ];
//
//   return isBlogPost
//     ? [
//         ...schemaOrgJSONLD,
//         {
//           '@context': 'http://schema.org',
//           '@type': 'BreadcrumbList',
//           itemListElement: [
//             {
//               '@type': 'ListItem',
//               position: 1,
//               item: {
//                 '@id': url,
//                 name: title,
//                 image,
//               },
//             },
//           ],
//         },
//         {
//           '@context': 'http://schema.org',
//           '@type': 'BlogPosting',
//           url,
//           name: title,
//           alternateName: config.title,
//           headline: title,
//           image: {
//             '@type': 'ImageObject',
//             url: image,
//           },
//           description,
//           author: {
//             '@type': 'Person',
//             name: 'Jason Lengstorf',
//           },
//           publisher: {
//             '@type': 'Organization',
//             url: 'https://lengstorf.com',
//             logo: config.logo,
//             name: 'Jason Lengstorf',
//           },
//           mainEntityOfPage: {
//             '@type': 'WebSite',
//             '@id': config.url,
//           },
//           datePublished,
//         },
//       ]
//     : schemaOrgJSONLD;
// };

const SEO = ({
  currentLocale,
  url: urlProp,
  icon: iconProp,
  lang: langPropDefined,
  image: imageProp,
  title: titleProp,
  description: descriptionProp,
  children,
}) => {
  const langProp = langPropDefined || currentLocale || 'fr'
  const complementTitle = siteConfig.titleTemplate.substring(2)
  // Either the default url or just the extension
  const url =
    (urlProp &&
      urlProp === true && [
        <link
          {...{ key: 'default', rel: 'canonical', href: siteConfig.baseUrl }}
        />,
        <meta
          {...{ key: 'fb', property: 'og:url', content: siteConfig.baseUrl }}
        />,
      ]) ||
    (urlProp &&
      typeof urlProp === 'string' && [
        <link
          {...{
            key: 'default',
            rel: 'canonical',
            href: `${siteConfig.baseUrl}${
              langProp !== 'fr' ? `/${langProp}` : ''
            }${urlProp}`,
          }}
        />,
        <meta
          {...{
            key: 'fb',
            property: 'og:url',
            content: `${siteConfig.baseUrl}${
              langProp !== 'fr' ? `/${langProp}` : ''
            }${urlProp}`,
          }}
        />,
      ]) ||
    null

  // Either the default url or just the extension
  const icon =
    (iconProp && iconProp === true && (
      <link
        {...{
          rel: 'icon',
          type: 'image/png',
          href: siteConfig.favicon,
          sizes: '75x75',
        }}
      />
    )) ||
    (iconProp && typeof iconProp === 'object' && (
      <link
        {...{
          rel: 'icon',
          type: iconProp.type || 'image/png',
          href: iconProp.href,
          sizes: iconProp.sizes || '32x32',
        }}
      />
    )) ||
    null

  const lang =
    (langProp && typeof langProp === 'string' && (
      <html lang={langProp} {...{ amp: false }} />
    )) ||
    null

  const image =
    (imageProp &&
      typeof imageProp === 'boolean' && [
        <meta
          {...{ key: 'default', name: 'image', content: siteConfig.ogImg }}
        />,
        <meta
          {...{ key: 'fb', property: 'og:image', content: siteConfig.ogImg }}
        />,
        <meta
          {...{ key: 'twt', name: 'twitter:image', content: siteConfig.ogImg }}
        />,
      ]) ||
    null

  const title =
    (titleProp &&
      titleProp === true && [
        <title {...{ key: 'default' }}>{siteConfig.title[langProp]}</title>,
        <meta
          {...{
            key: 'fb',
            property: 'og:title',
            content: siteConfig.title[langProp],
          }}
        />,
        <meta
          {...{
            key: 'twt',
            name: 'twitter:title',
            content: siteConfig.title[langProp],
          }}
        />,
      ]) ||
    (titleProp &&
      typeof titleProp === 'string' && [
        <title {...{ key: 'default' }}>{titleProp}</title>,
        <meta
          {...{
            key: 'fb',
            property: 'og:title',
            content: `${titleProp}${complementTitle}`,
          }}
        />,
        <meta
          {...{
            key: 'twt',
            name: 'twitter:title',
            content: `${titleProp}${complementTitle}`,
          }}
        />,
      ]) ||
    null

  const description =
    (descriptionProp &&
      descriptionProp === true && [
        <meta
          {...{
            key: 'default',
            name: 'description',
            content: siteConfig.description[langProp],
          }}
        />,
        <meta
          {...{
            key: 'fb',
            property: 'og:description',
            content: siteConfig.description[langProp],
          }}
        />,
        <meta
          {...{
            key: 'twt',
            name: 'twitter:description',
            content: siteConfig.description[langProp],
          }}
        />,
      ]) ||
    (descriptionProp &&
      typeof descriptionProp === 'string' && [
        <meta
          {...{
            key: 'default',
            name: 'description',
            content: descriptionProp,
          }}
        />,
        <meta
          {...{
            key: 'fb',
            property: 'og:description',
            content: descriptionProp,
          }}
        />,
        <meta
          {...{
            key: 'twt',
            name: 'twitter:description',
            content: descriptionProp,
          }}
        />,
      ]) ||
    null

  // const postMeta = postData.frontmatter || {}
  //
  // const title = postMeta.title || config.title
  // const description =
  //   postMeta.description || postData.excerpt || config.description
  // const image = `${config.url}${postImage}` || config.image
  // const url = postMeta.slug
  //   ? `${config.url}${path.sep}${postMeta.slug}`
  //   : config.url
  // const datePublished = isBlogPost ? postMeta.datePublished : false
  //
  // const schemaOrgJSONLD = getSchemaOrgJSONLD({
  //   isBlogPost,
  //   url,
  //   title,
  //   image,
  //   description,
  //   datePublished,
  // })

  return (
    <Helmet
      {...{
        titleTemplate: siteConfig.titleTemplate,
        defaultTitle: siteConfig.defaultTitle,
      }}
    >
      {/* General tags */}
      {url}
      {icon}
      {lang}
      {image}
      {title}
      {description}

      <meta property="og:type" content="website" />

      {/* Schema.org tags */}
      {/* <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script> */}

      {/* OpenGraph tags */}
      {/*
      {isBlogPost ? <meta property="og:type" content="article" /> : null}
      <meta property="fb:app_id" content={config.fbAppID} /> */}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content={config.twitter} /> */}

      {children}
    </Helmet>
  )
}

SEO.propTypes = {
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  // lang: PropTypes.string.isRequired, // NOTE: can not make it required as component can be nested under other SEO component
  image: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

SEO.defaultProps = {
  url: false,
  icon: false,
  // lang: false,
  image: false,
  title: false,
  description: false,
}

export default props => (
  <TranslationsConsumer>
    {({ currentLocale }) => (
      <SEO
        {...{
          currentLocale,
          ...props,
        }}
      />
    )}
  </TranslationsConsumer>
)
