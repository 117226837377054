const slugify = require('slugify')

const canonize = (name, camelCaseToo = false) => {
  const str = camelCaseToo ? name.replace(/[A-Z]/g, s => `-${s}`) : name

  return slugify(str, {
    replacement: '-',
    remove: /[$*_+~.,()'"!:@?/]/g,
    lower: true,
  })
}

module.exports = canonize
