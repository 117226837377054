import styled from '@emotion/styled'
import { space, typography, color } from 'styled-system'

const Link = styled.a(space, typography, color)

Link.displayName = 'Link'
Link.defaultProps = {
  color: 'blue',
  target: '_blank',
  rel: 'noopener noreferrer',
}

export default Link
