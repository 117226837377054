/* eslint-disable no-undef, react/prop-types */
import { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'
// import useInterval from '../../utils/useInterval'
import createBoardgame from '../../types/boardgame'
import createExhibitor from '../../types/exhibitor'
import createAnimation from '../../types/animation'
import createGuest from '../../types/guest'

// const WAIT_INTERVAL = 500
// const ENTER_KEY = 13
const ARRAY_LENGTH = 7

const query = graphql`
  query Search {
    localSearchBoardgames {
      index
      store
    }
    localSearchExhibitors {
      index
      store
    }
    localSearchAnimations {
      index
      store
    }
    localSearchGuests {
      index
      store
    }
  }
`

const searchOptions = {
  limit: ARRAY_LENGTH + 1,
  // threshold: 5, // >= threshold
  // depth: 3,     // <= depth
  // callback: function (results) {
  //   // ...
  // }

  // where: item => {
  //   console.log('IN THE WHERE !')
  //   return item.cat === 'comedy'
  // },
}

const SearchContainer = ({ children, ...props }) => {
  // const [timer, setTimer] = useState(0)
  const [input, setInput] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  const {
    localSearchBoardgames: { index: bgIndex, store: bgStore } = {},
    localSearchExhibitors: { index: eIndex, store: eStore } = {},
    localSearchAnimations: { index: aIndex, store: aStore } = {},
    localSearchGuests: { index: gIndex, store: gStore } = {},
  } = useStaticQuery(query)

  const exhibitors = useFlexSearch(
    input,
    eIndex,
    JSON.parse(eStore),
    searchOptions,
  ).map(createExhibitor)
  const animations = useFlexSearch(
    input,
    aIndex,
    JSON.parse(aStore),
    searchOptions,
  ).map(createAnimation)
  const boardgames = useFlexSearch(
    input,
    bgIndex,
    JSON.parse(bgStore),
    searchOptions,
  ).map(createBoardgame)
  const guests = useFlexSearch(
    input,
    gIndex,
    JSON.parse(gStore),
    searchOptions,
  ).map(createGuest)

  // useInterval(() => {
  //   // Your custom logic here
  //   setTimer(timer + 1)
  // }, 1000)

  const inputChange = e => {
    // setTimer(0)

    if (typeof e === 'string') {
      setInput(e)
    } else {
      setInput(e.target.value)
    }

    // this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL)

    // this.lookInEntries(e.target.value.toLowerCase())
  }

  // const handleKeyDown = e => {
  //   if (e.keyCode === ENTER_KEY) {
  //     this.triggerChange()
  //   }
  // }

  // const triggerChange = () => {
  //   const { input } = this.state
  //   this.lookInEntries(input)
  // }

  const handleFocus = setIsFocused

  return children({
    ...props,
    input,
    isFocused,
    exhibitors,
    animations,
    boardgames,
    guests,
    inputChange,
    handleFocus,
  })
}

export default SearchContainer
