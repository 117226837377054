const anchorme = require('anchorme').default

const addInlineLinks = string => {
  const str = anchorme(string, {
    attributes: [
      { name: 'target', value: '_blank' },
      { name: 'rel', value: 'noopener' },
    ],
  })
  return str
}

module.exports = addInlineLinks
