import styled from '@emotion/styled'
import ButtonTransparent from './ButtonTransparent'

const Close = styled(ButtonTransparent)(
  {
    p: 0,
    fontSize: 3,
    lineHeight: 1,
    children: '×',
  },
  {
    width: '24px',
    height: '24px',
  },
  'lineHeight',
)

Close.displayName = 'Close'

export default Close
