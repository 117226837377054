/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import PropTypes from 'prop-types'
import { Flex, Box, Image } from '../../elements'

const Album = ({ album }) => {
  if (!album || !album[0]) return null

  // TODO: open in Modal
  return (
    <Flex
      {...{
        flexWrap: 'wrap',
      }}
    >
      {album.map(pic => (
        <Box
          {...{
            key: pic.id,
            width: ['33.3333333%', '25%', '20%'],
            // width: [100, 120, 140],
            height: [100, 120, 140],
            p: 1,
          }}
        >
          <Image
            {...{
              featuredImage: pic,
              // src: featuredImage.thumbnails.large.url,

              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      ))}
    </Flex>
  )
}

// Album.propTypes = {
//   boardgame: PropTypes.object.isRequired,
// }

export default Album
