/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import { css, jsx } from '@emotion/core'

import { Flex, Text, FeatherIcon, Box } from '../../elements'

const TournamentDetails = ({
  tournamentRules,
  maxParticipants,
  price,
  color,
  t,
}) => (
  <React.Fragment>
    <Flex
      {...{
        // alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'space-between',
        pt: 2,
        color,
      }}
    >
      {maxParticipants > -1 && (
        <Flex
          {...{
            // alignItems: 'center',
            // mr: 2,
            my: 1,
          }}
        >
          <FeatherIcon
            {...{
              icon: 'players',

              color,
              fill: color,
              size: 2,
              // height: 30,
              // width: 30,
              mr: 1,
              // mb: 1,
            }}
          />
          <Text
            {...{
              fontSize: 2,
              color,
              // css: css`flex-shrink:1;`,
            }}
          >
            {`${maxParticipants} ${t('seats')}`}
          </Text>
        </Flex>
      )}
      {price > -1 && (
        <Text {...{ my: 1, color }}>
          <Text {...{ as: 'span', fontWeight: 'bold', color }}>€ </Text>
          {price}
        </Text>
      )}
      {Boolean(tournamentRules) && (
        <Text.box
          {...{
            color,
            // pt: 2,
            // px: [2, 2, 3],
            m: null,
            my: 1,
            fontSize: 1,
            css: css`
              text-transform: uppercase;
            `,
          }}
        >
          {tournamentRules}
        </Text.box>
      )}
    </Flex>
  </React.Fragment>
)

export default TournamentDetails
