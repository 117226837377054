/* eslint-disable react/prop-types */
import React from 'react'
import { css } from '@emotion/core'

import { Paper, FeatherIcon, H1, Flex } from '../../elements'

const HeadingStaticPage = ({
  bg,
  color,
  title,
  icon,
  stroke,
  fill,
  ...props
}) => (
  <Paper
    {...{
      bg,
      ...props,
    }}
  >
    {icon ? (
      <Flex
        {...{
          alignItems: 'flex-center',
        }}
      >
        <FeatherIcon
          {...{
            icon,
            color,
            stroke,
            fill,
            // fill: 'text',
            size: 3,
            mx: 1,
            // mb: 1,
          }}
        />
        <H1
          {...{
            fontSize: 4,
            pr: 1,
            color,
            css: css`
              flex-grow: 1;
              text-transform: uppercase;
            `,
          }}
        >
          {title}
        </H1>
      </Flex>
    ) : (
      <H1
        {...{
          fontSize: 4,
          pr: 1,
          color,
          css: css`
            flex-grow: 1;
            text-transform: uppercase;
          `,
        }}
      >
        {title}
      </H1>
    )}
  </Paper>
)

export default HeadingStaticPage
