/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import _ from 'lodash'
// import { Flex, FeatherIcon, GatsbyLink } from '.'
import { css } from '@emotion/core'

import Flex from './Flex'
import FeatherIcon from './FeatherIcon'
import GatsbyLink from './GatsbyLink'
import Text from './Text'

import { Consumer as TranslationsConsumer } from '../containers/Translations'

const Breadcrumbs = ({ path, ...props }) => {
  const steps = path.split('/')
  const isLocalizedPath = steps[1] === 'en' || steps[1] === 'nl'
  const isUndefined = elem => typeof elem === 'undefined'
  const showLinks =
    (!isLocalizedPath && !isUndefined(steps[2])) ||
    (isLocalizedPath && !isUndefined(steps[3]))

  return (
    <TranslationsConsumer>
      {({ t }) => (
        <Flex
          {...{
            // flexDirection: 'column',
            alignItems: 'center',
            css: css`
              flex-grow: 1;
            `,
          }}
        >
          <Flex
            {...{
              flexWrap: 'wrap',
              flexDirection: ['column', 'column', 'row'],
              // justifyContent: 'center',
              fontSize: 1,
              color: 'red',
              ml: 1,
              css: css`
                text-transform: uppercase;
              `,
            }}
            {...props}
          >
            {showLinks ? (
              steps.map((s, i, stepsArray) => {
                if (s.match(/offline.plugin.app.shell.fallback/)) {
                  return null
                }
                const link = `${stepsArray.slice(0, i + 1).join('/')}/`
                const showLink = s.length && s !== 'en' && s !== 'nl'
                const word = s.split('-').join(' ')
                const cat = _.camelCase(s)

                return showLink ? (
                  <Flex
                    {...{
                      key: link,

                      alignItems: 'center',
                    }}
                  >
                    <FeatherIcon
                      {...{
                        key: 'icon',
                        icon: 'chevron-right',
                        color: 'red',
                        size: 2,
                        // mr: 1,
                      }}
                    />
                    <GatsbyLink
                      {...{
                        key: 'link',
                        to: link,
                        overWriteLocale: true,
                      }}
                    >
                      {t(cat) || word}
                    </GatsbyLink>
                  </Flex>
                ) : null
              })
            ) : (
              <Text>
                <strong>Brussels Games Festival 2019</strong>
                <br />
                23/08 > 25/08
                <br />
                <span css={{ textTransform: 'capitalize' }}>
                  {t('bgfAddress')}
                </span>
              </Text>
            )}
          </Flex>
        </Flex>
      )}
    </TranslationsConsumer>
  )
}

export default Breadcrumbs
