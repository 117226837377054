import styled from '@emotion/styled'
import {
  space,
  width,
  color,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  borderRadius,
} from 'styled-system'
import Img from 'gatsby-image'

const GatsbyImg = styled(Img)(
  {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
  space,
  width,
  color,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  borderRadius,

  ({ objectFit }) => ({
    // height: height ? `${height}!important` : null,
    // borderRadius: borderRadius ? `${borderRadius}` : null,
    img: {
      objectFit: objectFit ? `${objectFit}!important` : 'cover',
    },
    [`.gatsby-image-outer-wrapper &`]: {
      // borderRadius: borderRadius ? `${borderRadius}` : null,
    },
  }),
)

GatsbyImg.displayName = 'GatsbyImg'
GatsbyImg.defaultProps = {}

export default GatsbyImg
