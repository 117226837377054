const _ = require('lodash')

const routes = require(`../utils/routes`)
const safeUrl = require(`../utils/safeUrl`)
const canonize = require(`../utils/canonize`)
const filterOutByArray = require(`../utils/filterOutByArray`)
const filterOutByNumber = require(`../utils/filterOutByNumber`)
const getYoutubeParamsFromUrl = require(`../utils/getYoutubeParamsFromUrl`)

const filterOut = {
  accessibility: filterOutByArray,
  audience: ({ filters, entryParam }) =>
    filterOutByArray({ filters, entryParam, operator: 'or' }),
  duration: filterOutByNumber,
  maxPlayers: ({ filters, entryParam }) =>
    filterOutByNumber({ filters, entryParam, min: true }),
}

const createBoardgame = ({
  name,
  nameCanonical,
  nameFallback,
  status,
  proto,
  pitch,
  description,
  website,
  facebook,
  youtubeVideo,
  featuredImage,
  // authors,
  // illustrators,
  minPlayers,
  maxPlayers,
  duration,
  age,
  audience,
  accessibility,
  exhibitors,
  animations,
  authors,
  illustrators,
  inventedBy,
  illustratedBy,

  // ----------------------------
  Name,
  NameFR,
  NameEN,
  NameNL,
  Status,
  Proto,
  PitchEN,
  PitchFR,
  PitchNL,
  DescriptionEN,
  DescriptionFR,
  DescriptionNL,
  Website,
  Facebook,
  YoutubeVideo,
  FeaturedImage: [FeaturedImage] = [],
  // Authors___NODE,
  // Illustrators___NODE,
  MinPlayers,
  MaxPlayers,
  Duration,
  Age,
  Audience,
  Accessibility,
  Exhibitors,
  Animations,
  _x_Authors: Authors,
  _x_Illustrators: Illustrators,
  InventedBy,
  IllustratedBy,
  // Tournaments___NODE,
  // Exhibitors___NODE,
  // ----------------------
  imageUrl,
}) => {
  const nameOk = name || {
    en: NameEN,
    fr: Name || NameFR,
    nl: NameNL || NameEN,
  }
  const nameFallbackOk = nameFallback || {
    en: NameEN || Name || NameNL,
    fr: Name || NameEN || NameNL,
    nl: NameNL || NameEN || Name,
  }
  const defaultName = nameOk.en || nameOk.fr || nameOk.nl
  const nameCanonicalOk = nameCanonical || canonize(defaultName)

  const isProto = !!(proto || Proto)

  const tempAccess = accessibility || Accessibility || []

  const youtubeVideoUrl =
    youtubeVideo ||
    getYoutubeParamsFromUrl(safeUrl(YoutubeVideo, { protocol: 'https' }))

  const boardgame = {
    name: nameOk,
    nameFallback: nameFallbackOk,
    nameCanonical: nameCanonicalOk,
    status: status || Status,
    proto: isProto,
    pitch: pitch || {
      en: PitchEN,
      fr: PitchFR,
      nl: PitchNL,
    },
    description: description || {
      en: DescriptionEN,
      fr: DescriptionFR,
      nl: DescriptionNL,
    },
    website: website || safeUrl(Website),
    facebook: facebook || safeUrl(Facebook, { protocol: 'https' }),
    youtubeVideo: youtubeVideoUrl,
    featuredImage:
      featuredImage ||
      FeaturedImage ||
      (imageUrl && {
        thumbnails: { large: { url: imageUrl } },
      }),

    minPlayers: minPlayers || MinPlayers,
    maxPlayers: maxPlayers || MaxPlayers,
    duration: duration || Duration / 60,
    age: age || Age,
    audience: audience || Audience,
    accessibility: tempAccess.filter(a => a !== 'differentLanguage'),
    authors: authors || Authors,
    illustrators: illustrators || Illustrators,

    exhibitors: exhibitors || Exhibitors,
    animations: animations || Animations,
    inventedBy: inventedBy || InventedBy,
    illustratedBy: illustratedBy || IllustratedBy,

    // ---------------------
    relativePath: isProto
      ? routes.proto(nameCanonicalOk)
      : routes.boardgame(nameCanonicalOk),
  }

  const isFilteredBy = (property, filterValue) => {
    return filterOut[property]({
      filters: filterValue,
      entryParam: boardgame[property],
    })
  }

  return Object.freeze({ ...boardgame, isFilteredBy })
}

module.exports = createBoardgame
