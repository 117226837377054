/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import styled from '@emotion/styled'
import { space, width, height, color, borderRadius } from 'styled-system'

import FeatherIcon from './FeatherIcon'

// const WPLogoString =
//   'https://www.wanna-play.be/static/BOUTON1-7ee2c573c0af1e88272e363046bef8d1-58b2c.png'

const ImageSys = styled.img(
  {
    display: 'block',
    maxWidth: '100%',
    // height: 'auto',
  },
  space,
  width,
  height,
  color,
  borderRadius,
  ({ objectFit }) => ({
    objectFit: objectFit ? `${objectFit}` : 'cover',
  }),
)

ImageSys.displayName = 'Image'

const Image = ({
  featuredImage,
  imageSize = 'large',
  src: source,
  ...props
}) => {
  const src =
    (featuredImage &&
      featuredImage.thumbnails &&
      featuredImage.thumbnails[imageSize] &&
      featuredImage.thumbnails[imageSize].url) ||
    source ||
    // WPLogoString
    null

  return src ? (
    <ImageSys {...{ src, blacklist: ['objectFit'], ...props }} />
  ) : (
    <FeatherIcon
      {...{
        icon: 'image',
        stroke: 'lightGrey',
        ...props,
      }}
    />
  )
}

export default Image
