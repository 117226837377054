import styled from '@emotion/styled'
// import sys from 'styled-system'
import { border, space, layout, shadow } from 'styled-system'

const Input = styled.input(
  {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit',
    fontFamily: 'inherit',
    display: 'inline-block',
    verticalAlign: 'middle',
    // appearance: 'none',
    '&:focus': {
      outline: 'none',
      // boxShadow: `inset 0 0 0 1px ${theme('colors.blue')(props)}`,
    },
    '&:disabled': {
      opacity: 1 / 4,
    },
  },
  border,
  space,
  layout,
  shadow,
)

Input.displayName = 'Input'

Input.defaultProps = {
  type: 'text',
  px: 1,
  py: 2,
  m: 0,
  width: '100%',
  border: 0,
  borderColor: 'gray',
  boxShadow: 1,
  borderRadius: 2,
  bg: 'transparent',
}

export default Input
