import styled from '@emotion/styled'
import {
  // borders,
  // borderColor,
  // borderRadius,
  // boxShadow,
  // backgroundImage,
  // backgroundSize,
  // backgroundPosition,
  // backgroundRepeat,
  // opacity,
  // variant,

  space,
  layout,
  color,
  border,
  shadow,
} from 'styled-system'

// import Box from './Box'

// const themed = key => props => props.theme[key]

// const cards = variant({ key: 'cards' })

// const Card = styled(Box)(
//   borders,
//   borderColor,
//   border,
//   shadow,
//   backgroundImage,
//   backgroundSize,
//   backgroundPosition,
//   backgroundRepeat,
//   opacity,
//   cards,
//   themed('Card'),
// )

const Paper = styled('div')(
  {
    overflow: 'hidden',
  },
  space,
  layout,
  color,
  border,
  shadow,
)

Paper.displayName = 'Paper'

Paper.defaultProps = {
  p: 2,
  mb: 2,
  // bg: 'white',
  // borderRadius: 2,
  boxShadow: 'paper',
}

export default Paper
