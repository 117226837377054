import React from 'react'
import styled from '@emotion/styled'
import {
  space,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  textAlign,
  lineHeight,
} from 'styled-system'
import Box from './Box'

const Text = styled.div(
  space,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  textAlign,
  lineHeight,
)

Text.displayName = 'Text'

Text.defaultProps = {
  m: 0,
  color: 'text',
}

Text.box = props => <Text {...{ as: Box, ...props }} />

export default Text
