/* eslint-disable no-undef, react/prop-types */
import React from 'react'

import { Paper, H2 } from '../../elements'
// import { FavoriteSelector, Translations } from '../../containers'

// const placesWords = ['Chapiteau', 'Forum']

const HeadingPaperSection = ({ bg, color, children, ...props }) => (
  <Paper {...{ bg, px: [2, 2, 3], mt: 2, ...props }}>
    <H2 {...{ color }}>{children}</H2>
  </Paper>
)

export default HeadingPaperSection
