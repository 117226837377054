import styled from '@emotion/styled'
import { space, color, zIndex, top, right, bottom, left } from 'styled-system'

const Position = styled()({}, space, color, zIndex, top, right, bottom, left)

Position.displayName = 'Position'

export const Relative = styled(Position)({
  position: 'relative',
})
Relative.displayName = 'Relative'

export const Absolute = styled(Position)({
  position: 'absolute',
})
Absolute.displayName = 'Absolute'

export const Fixed = styled(Position)({
  position: 'fixed',
})
Fixed.displayName = 'Fixed'

export const Sticky = styled(Position)({
  position: 'sticky',
})
Sticky.displayName = 'Sticky'

export default Position
