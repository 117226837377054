// /* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import PropTypes from 'prop-types'
import { Flex, Box, Fixed, Text, Button } from '../elements'
import { Translations } from '.'

class CookieAlert extends React.Component {
  // static propTypes = {
  //   t: PropTypes.func,
  // }
  // static defaultProps = {
  //   t: null,
  // }
  state = {
    cookieAccepted: true,
  }
  componentDidMount() {
    this.checkCookie()
  }
  checkCookie = () => {
    const cookieAccepted = localStorage.getItem('accepts-cookies') === 'true'
    this.setState({ cookieAccepted })
  }
  handleClick = () => {
    localStorage.setItem('accepts-cookies', 'true')
    this.setState({ cookieAccepted: true })
  }
  render() {
    return this.state.cookieAccepted === false ? (
      <Translations.Consumer>
        {({ t }) => (
          <React.Fragment>
            <Fixed
              {...{
                is: Flex,

                bottom: 0,
                left: 0,
                right: 0,
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                {...{
                  bg: 'red',
                  color: 'white',
                  // width: 3 / 4,
                  p: 2,
                  mx: 1,
                  borderRadius: 2,
                }}
              >
                <Text
                  {...{
                    fontSize: 1,
                  }}
                >
                  {t('cookieWarning')}
                </Text>
                <Button
                  {...{
                    onClick: this.handleClick,

                    bg: 'red',
                    color: 'white',
                    border: 8,
                    mt: 2,
                    mx: 'auto',
                    css: {
                      display: 'block!important',
                    },
                  }}
                >
                  OK
                </Button>
              </Box>
            </Fixed>
          </React.Fragment>
        )}
      </Translations.Consumer>
    ) : null
  }
}

export default CookieAlert
