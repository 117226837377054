import React from 'react'
import styled from '@emotion/styled'
import {
  flexbox,
  // flexWrap,
  // flexDirection,
  // alignItems,
  // alignContent,
  // justifyContent,
} from 'styled-system'
import Box from './Box'

const Flex = styled(Box)(
  {
    display: 'flex',
  },
  flexbox,
  // flexWrap,
  // flexDirection,
  // alignItems,
  // alignContent,
  // justifyContent,
)

Flex.displayName = 'Flex'
// Flex.defaultProps = {
//   blacklist: ['minHeight'],
// }

Flex.container = props => (
  <Flex
    {...{
      width: '100%',
      px: [2, 2, 3],
      py: [2, 2, 3],
      ...props,
    }}
  />
)
Flex.section = props => (
  <Flex
    {...{
      as: 'section',
      width: '100%',
      px: [2, 2, 3],
      ...props,
    }}
  />
)

export default Flex
