/* eslint-disable no-undef, react/prop-types */
import React, { Component } from 'react'
import WindowSizeListener from 'react-window-size-listener'

// const isSSR = typeof window === 'undefined'

class DrawerContainer extends Component {
  state = {
    open: false,
    windowWidth: 0,
    // locale: '',
  }

  componentDidMount() {
    // eslint-disable-next-line no-console
    // console.log(isSSR)
  }

  setWindowWidth = windowWidth => {
    this.setState({ windowWidth })
  }

  toggle = bool => {
    this.setState({ open: typeof bool === 'boolean' ? bool : !this.state.open })
  }

  render() {
    const { children } = this.props
    return (
      <WindowSizeListener
        {...{
          onResize: ({ windowWidth }) => {
            this.setWindowWidth(windowWidth)
          },
        }}
      >
        {children({
          ...this.props,
          ...this.state,
          toggle: this.toggle,
        })}
      </WindowSizeListener>
    )
  }
}

export default DrawerContainer
