/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import { css } from '@emotion/core'

import { Flex, Circle, FeatherIcon } from '../../elements'

const FacebookCircle = ({ color, backgroundColor, link }) =>
  link ? (
    <Flex
      {...{
        flexDirection: 'column',
      }}
    >
      <Circle
        {...{
          as: 'a',
          href: link,
          target: `_blank`,
          rel: `noopener noreferrer`,

          bg: backgroundColor,
          px: 0,
          py: 0,
          mx: 0,
          my: 0,
          size: 5,
          css: css`
            display: flex;
            justify-content: center;
          `,
        }}
      >
        <FeatherIcon
          {...{
            icon: 'facebook',
            stroke: color,
            fill: color,
            size: 2,
            // m: 'auto',
            // mx: 1,
            // mb: 1,
          }}
        />
      </Circle>
    </Flex>
  ) : null

export default FacebookCircle
