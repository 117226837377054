const favicon = require('../assets/images/favicon.png')
const ogImg = require('../assets/images/bgf_og-img-2019.jpg')

const baseUrl = 'https://app.brusselsgamesfestival.be'

const siteConfig = {
  baseUrl,
  favicon,
  ogImg: `${baseUrl}${ogImg}`,

  titleTemplate: '%s | Brussels Games Festival',
  defaultTitle: 'Companion App | Brussels Games Festival',
  title: {
    fr: 'App Companion',
    en: 'Companion App',
    nl: 'Companion App',
  },
  baseTitle: {
    fr: 'Brussels Games Festival',
    en: 'Brussels Games Festival',
    nl: 'Brussels Games Festival',
  },
  description: {
    fr: '',
    en: '',
    nl: '',
  },

  // title: 'Jason Lengstorf · There’s more to life than hustle & grind.',
  // description: '',
  // url: 'https://lengstorf.com',
  // image: 'https://lengstorf.com/images/jason-lengstorf.jpg',
  // logo: 'https://lengstorf.com/android-chrome-512x512.png',
  // twitter: '@jlengstorf',
  // fbAppID: '',
}

module.exports = siteConfig
