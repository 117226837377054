import React from 'react'
// import system from 'styled-system'
import { css, jsx } from '@emotion/core'

import { Heading } from '@rebass/emotion'

Heading.h1 = props => (
  <Heading
    {...{
      as: 'h1',
      fontSize: 4,
      fontWeight: 'regular',
      color: 'heading',
      css: css`
        text-transform: uppercase;
      `,
      ...props,
    }}
  />
)
Heading.h2 = props => <Heading.h1 {...{ as: 'h2', fontSize: 3, ...props }} />
Heading.h3 = props => <Heading {...{ as: 'h3', fontSize: 3, ...props }} />
Heading.h4 = props => <Heading {...{ as: 'h4', fontSize: 2, ...props }} />
Heading.h5 = props => <Heading {...{ as: 'h5', fontSize: 1, ...props }} />
Heading.h6 = props => <Heading {...{ as: 'h6', fontSize: 0, ...props }} />

export default Heading
