/* eslint-disable no-undef, react/prop-types */
import React from 'react'

import { Flex, FeatherIcon } from '../../elements'

const Dots = () => (
  <Flex
    {...{
      // height: 50,
      css: {
        flexGrow: 1,
        justifyContent: 'center',
        minHeight: 50,
      },
    }}
  >
    {[1, 2, 3].map(i => (
      <FeatherIcon
        {...{
          key: i,
          icon: 'circle',
          stroke: 'text',
          fill: 'text',
          size: 1,
          mr: 1,
        }}
      />
    ))}
  </Flex>
)

export default Dots
