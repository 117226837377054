import React from 'react'
import Box from './Box'

const Fixed = props => (
  <Box
    {...props}
    css={{
      position: 'fixed',
      ...props.css,
    }}
  />
)

export default Fixed
