// import { useState } from 'react'
import useLocalStorage from './useLocalStorage'

const useFilters = (key, initialValue, filtersArray) => {
  const [{ filtersOpen }, setFiltersOpen] = useLocalStorage(
    `filters.${key}.open`,
    {
      filtersOpen: true,
    },
  )
  const [filterValues, setFilterValues] = useLocalStorage(
    `filters.${key}`,
    initialValue,
  )

  const filters = filtersArray.reduce(
    (acc, filter) =>
      // there is either a min and max value
      filter.minValue
        ? {
            ...acc,
            [filter.name]: {
              min: filter.minValue,
              max: filter.maxValue,
            },
          }
        : // or a list of values
          {
            ...acc,
            [filter.name]: filter.values,
          },
    {},
  )

  const setFilterList = ({ property, val, isSelected }) => {
    setFilterValues({
      ...filterValues,
      [property]: {
        ...filterValues[property],
        [val]: !isSelected,
      },
    })
  }
  const setFilterValue = ({ property, val }) => {
    setFilterValues({
      ...filterValues,
      [property]: val,
    })
  }
  const resetFilters = () => {
    setFilterValues(initialValue)
  }

  const filtersSelected = Object.entries(filterValues).reduce(
    (acc, [key, val]) => {
      if (!val || typeof val !== 'object') return acc

      const tagsSelected = Object.entries(val)
        .filter(([tag, bool]) => bool)
        .map(([tag]) => tag)
      return [...acc, ...tagsSelected]
    },
    [],
  )

  return {
    filterValues,
    setFilterValues,
    setFilterValue,
    filters,
    setFilterList,
    resetFilters,
    filtersOpen,
    setFiltersOpen: bool => {
      setFiltersOpen({ filtersOpen: bool })
    },
    filtersSelected,
  }
}

export default useFilters
