import React from 'react'

import { Box, Flex } from '../../elements'
import { HeadingPaperSection, GuestEntry, Dots } from '..'

const helperName = 'guests'

const SectionTypes = ({
  [helperName]: x = [],
  t,
  headingPaperSection,
  dropStatusCheck,
  showDots,
  title,
  ...props
}) => {
  const filteredList = x && x.filter(xi => xi.status === 'published')
  const filteredListExist = !!(filteredList && filteredList.length)
  const list = dropStatusCheck ? x : filteredList

  return (
    !!(dropStatusCheck || filteredListExist) && (
      <Box {...props}>
        <HeadingPaperSection
          {...{
            bg: helperName,
            color: 'textAlt',
            ...headingPaperSection,
          }}
        >
          {t(title || helperName)}
        </HeadingPaperSection>
        <Flex.container {...{ flexWrap: 'wrap' }}>
          {list.map(y => (
            <GuestEntry
              {...{
                key: y.id || y.nameCanonical,
                guest: y,
              }}
            />
          ))}
          {showDots && <Dots />}
        </Flex.container>
      </Box>
    )
  )
}

export default SectionTypes
