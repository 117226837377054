/* eslint-disable no-undef, react/prop-types */

import React from 'react'
// import PropTypes from 'prop-types'
import { css } from '@emotion/core'

import {
  Flex,
  Box,
  Text,
  GatsbyLink,
  GatsbyImg,
  FeatherIcon,
} from '../../elements'

const height = '100px'

const MainCatLink = ({ to, image, text, color, icon }) => (
  <GatsbyLink.Flex
    {...{
      to,
      height,
      width: '100%',
      // maxWidth: '400px',
      bg: color,
      boxShadow: 'mainCatLink',
      // border: 1,
      // borderColor: 'sand',
      // justifyContent: 'center',
      px: [3, 3, 4],
      // css: {
      //   position: 'relative',
      //   zIndex: 'none',
      // },
    }}
  >
    <FeatherIcon
      {...{
        icon: `${icon}_main`,
        // color: 'sand',
        fill: 'sand',
        strokeWidth: '0px',
        size: '54px',
        // height: 30,
        // width: 30,
        mr: 2,
        // mb: 1,
      }}
    />
    <Text
      {...{
        children: text,
        // Styles
        color: 'sand',
        lineHeight: height,
        // textAlign: 'center',
        fontSize: 4,
        fontWeight: 'bold',
      }}
    />
  </GatsbyLink.Flex>
)
// <Flex
//   {...{
//     flexDirection: `column`,
//     alignItems: `center`,
//     width: [100, 120, 140],
//     // height: [100, 120, 140],
//     mx: 1,
//     my: 2,
//   }}
// >
//   <Box
//     {...{
//       border: 3,
//       borderRadius: '50%',
//       borderColor: color,
//       css: css`
//         overflox: hidden;
//       `,
//     }}
//   >
//     <GatsbyLink
//       {...{
//         to,
//         // borderRadius: '50%',
//       }}
//     >
//       <GatsbyImg
//         {...{
//           fluid: image.fluid,

//           minWidth: 80,
//           maxWidth: 120,
//           width: [80, 100, 120],
//           height: [80, 100, 120],
//           borderRadius: '50%',
//         }}
//       />
//     </GatsbyLink.box>
//   </Box>
//   <Text
//     {...{ color, display: 'block', textAlign: 'center', fontWeight: 'bold' }}
//   >
//     {text}
//   </Text>
// </Flex>

export default MainCatLink
