import React from 'react'
import { Card } from '@rebass/emotion'

const Badge = props => (
  <Card
    color="white"
    bg="blue"
    p={null}
    px={3}
    py={1}
    borderRadius={9999}
    {...props}
    css={{
      display: 'inline-block',
      cursor: props.onClick ? 'pointer' : 'auto',
    }}
  />
)

export default Badge
