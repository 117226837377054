const iconsConfig = {
  default: [
    // Gatsby Default
    {
      src: `icons/icon-48x48.png`,
      sizes: `48x48`,
      type: `image/png`,
    },
    {
      src: `icons/icon-72x72.png`,
      sizes: `72x72`,
      type: `image/png`,
    },
    {
      src: `icons/icon-96x96.png`,
      sizes: `96x96`,
      type: `image/png`,
    },
    {
      src: `icons/icon-144x144.png`,
      sizes: `144x144`,
      type: `image/png`,
    },
    {
      src: `icons/icon-192x192.png`,
      sizes: `192x192`,
      type: `image/png`,
    },
    {
      src: `icons/icon-256x256.png`,
      sizes: `256x256`,
      type: `image/png`,
    },
    {
      src: `icons/icon-384x384.png`,
      sizes: `384x384`,
      type: `image/png`,
    },
    {
      src: `icons/icon-512x512.png`,
      sizes: `512x512`,
      type: `image/png`,
    },
  ],
  // Icons for IOS
  ios: [
    {
      rel: 'apple-touch-icon',
      src: `icons/touch-icon-iphone.png`,
      sizes: `152x152`,
      type: `image/png`,
    },
    {
      rel: 'apple-touch-icon',
      src: `icons/touch-icon-ipad.png`,
      sizes: '152x152',
      type: `image/png`,
    },
    {
      rel: 'apple-touch-icon',
      src: `icons/touch-icon-iphone-retina.png`,
      sizes: `180x180`,
      type: `image/png`,
    },
    {
      rel: 'apple-touch-icon',
      src: `icons/touch-icon-ipad-retina.png`,
      sizes: `167x167`,
      type: `image/png`,
    },
  ],
  // Splash screens for IOS
  iosScreens: [
    {
      rel: 'apple-touch-startup-image',
      src: `icons/apple_splash_2048.png`,
      sizes: `2048x2732`,
      type: `image/png`,
    },
    {
      rel: 'apple-touch-startup-image',
      src: `icons/apple_splash_1668.png`,
      sizes: `1668x2224`,
      type: `image/png`,
    },
    {
      rel: 'apple-touch-startup-image',
      src: `icons/apple_splash_1536.png`,
      sizes: `1536x2048`,
      type: `image/png`,
    },
    {
      rel: 'apple-touch-startup-image',
      src: `icons/apple_splash_1125.png`,
      sizes: `1125x2436`,
      type: `image/png`,
    },
    {
      rel: 'apple-touch-startup-image',
      src: `icons/apple_splash_1242.png`,
      sizes: `1242x2208`,
      type: `image/png`,
    },
    {
      rel: 'apple-touch-startup-image',
      src: `icons/apple_splash_750.png`,
      sizes: `750x1334`,
      type: `image/png`,
    },
    {
      rel: 'apple-touch-startup-image',
      src: `icons/apple_splash_640.png`,
      sizes: `640x1136`,
      type: `image/png`,
    },
  ],
}

module.exports = iconsConfig
