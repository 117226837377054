/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import styled from '@emotion/styled'
// import sys from 'styled-system'

import { Consumer as TranslationsConsumer } from '../containers/Translations'
// import { themeGet } from 'styled-system'
import Flex from './Flex'

// eslint-disable-next-line no-console
// console.log(themeGet('site'))

const Page = styled(Flex)(
  {
    flexGrow: 1,
    flexFlow: `column`,
    // justifyContent: 'flex-start',
    // alignItems: 'center',
  },
  // ({ theme }) => ({
  //   '& > *': {
  //     maxWidth: theme.site.maxWidth,
  //     // margin: 'auto',
  //     // maxWidth: 1000,
  //     // border: 'solid 5px purple',
  //   },
  // }),
)

Page.displayName = 'Page'
Page.defaultProps = {
  role: 'main',
  className: 'page',
  blacklist: ['justifyContent', 'alignItems', 'pb'],
  pb: 3,
}

Page.container = props => (
  <Page
    {...{
      width: '100%',
      px: [2, 2, 3],
      ...props,
    }}
  />
)

export default props => {
  if (typeof props.children === 'function') {
    return (
      <TranslationsConsumer>
        {transProps => (
          <Page {...{ ...props }}>{props.children({ ...transProps })}</Page>
        )}
      </TranslationsConsumer>
    )
  }
  return <Page {...props} />
}
