import React from 'react'
import styled from '@emotion/styled'
import {
  compose,
  space,
  color,
  layout,
  typography,
  border,
  shadow,
  // background,
  // width,
  // maxWidth,
  // height,
  // space,
  // fontSize,
  // color,
  // flex,
  // order,
  // alignSelf,

  // // radii,
  // borders,
  // borderColor,
  // borderRadius,
  // boxShadow,
  // minHeight,
  // // flexGrow,
} from 'styled-system'

const Box = styled.div(
  {
    boxSizing: 'border-box',
    // minWidth: 0,
  },
  compose(
    space,
    color,
    layout,
    typography,
    border,
    shadow,
    // background,
  ),
  // width,
  // maxWidth,
  // height,
  // space,
  // fontSize,
  // color,
  // flex,
  // order,
  // alignSelf,
  // // radii,
  // borders,
  // borderColor,
  // borderRadius,
  // boxShadow,
  // minHeight,
  // // flexGrow,
)

Box.displayName = 'Box'

Box.container = props => (
  <Box
    {...{
      width: '100%',
      px: [2, 2, 3],
      py: [2, 2, 3],
      ...props,
    }}
  />
)

export default Box
