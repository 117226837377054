/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import { Flex, Badge } from '../../elements'
import { Consumer as TranslationsConsumer } from '../../containers/Translations'

const AnimationCategories = ({
  categories,
  color,
  bg,
  fontSize,
  container,
  ...props
}) => (
  <TranslationsConsumer>
    {({ t }) => (
      <Flex
        {...{
          flexWrap: 'wrap',
          ...(container && {
            px: [2, 2, 3],
            // py: [2, 2, 3],
          }),
          ...props,
        }}
      >
        {categories &&
          categories.map(
            cat =>
              cat && (
                <Badge
                  {...{
                    key: cat,
                    borderRadius: 3,
                    mx: '1px',
                    my: '1px',
                    p: null,
                    px: 2,
                    py: '2px',
                    // mr: 1,
                    color:
                      // (color && cat === 'tournaments' && 'tournament') ||
                      color || 'white',
                    bg:
                      // (cat === 'Proto' && 'protozone') ||
                      bg ||
                      (cat === 'tournaments' && 'tournament') ||
                      'animation',
                    fontSize,
                  }}
                >
                  {t(cat) || cat}
                </Badge>
              ),
          )}
      </Flex>
    )}
  </TranslationsConsumer>
)

export default AnimationCategories
