/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import PropTypes from 'prop-types'
import { css, jsx } from '@emotion/core'

import {
  RightDrawer as Drawer,
  // Fixed,
  // Close,
  Flex,
  Box,
  GatsbyLink,
  Button,
  FeatherIcon,
  Text,
  Link,
  Hr,
} from '../../elements'
import { Translations } from '../../containers'
import { theme } from '../../utils'

const width = 242
const { maxWidth } = theme.site
// const restMaxWidth = maxWidth - width

const RightDrawer = ({ open, toggle, windowWidth }) => {
  const drawerRelative = windowWidth > maxWidth
  const showDrawer = open || drawerRelative
  // eslint-disable-next-line no-console
  // console.log(currentLocale)
  return (
    <Translations.Consumer>
      {({ t, currentLocale, basePathname, queryString }) => (
        <React.Fragment>
          <Drawer
            {...{
              onClick: e => {
                e.stopPropagation()
              },
              open: showDrawer,
              position: drawerRelative ? 'relative' : 'fixed',

              side: 'right',
              // bg: 'white',
              bg: 'trans.0',
              color: 'darkGreen',
              // p: 2,
              // width: showDrawer ? width : 0,
              width,
              // display: 'none',
              // boxShadow: `5px 10px 8px 10px lightGray`,
              boxShadow: `drawer`,
              css: css`
                display: flex;
                justify-content: flex-end;
                flex-shrink: 0;
                z-index: 10;
              `,
            }}
          >
            <Flex
              {...{
                width,
                justifyContent: 'flex-end',
                bg: 'trans.0',
              }}
            >
              <Flex
                {...{
                  // width: width - 10,
                  width,
                  position: 'relative',
                  // boxShadow: `5px 10px 8px 10px lightGray`,
                  // // boxShadow: `drawer`,
                  flexDirection: 'column',
                  p: 2,
                  // css: css`flex-grow: 1;`,
                  css: {
                    backgroundImage: `linear-gradient(to bottom, ${
                      theme.colors.lightGreen
                    }, ${theme.colors.lighterGreen})`,
                  },
                }}
              >
                {!drawerRelative && (
                  <Box
                    {...{
                      onClick: () => {
                        toggle()
                      },

                      alignSelf: `flex-end`,
                      // px: 3,
                      // py: 2,
                      // pr: 2,
                    }}
                  >
                    <FeatherIcon
                      {...{
                        icon: 'x',
                        color: 'sand',
                        size: 3,
                        // mr: 1,
                      }}
                    />
                  </Box>
                )}
                <Flex
                  {...{
                    justifyContent: 'center',
                    py: 4,
                  }}
                >
                  {[{ lang: 'fr' }, { lang: 'en' }, { lang: 'nl' }].map(
                    ({ lang }) => {
                      const to =
                        lang === 'fr'
                          ? `/${basePathname}${queryString}`
                          : `/${lang}/${basePathname}${queryString}`

                      return (
                        <GatsbyLink
                          {...{
                            key: lang,
                            overWriteLocale: true,
                            to,
                            onClick: () => {
                              toggle()
                            },

                            // my: 3,
                            mx: 1,
                          }}
                        >
                          <Button
                            {...{
                              py: 1,
                              bg: lang === currentLocale ? 'darkGreen' : 'sand',
                              color:
                                lang === currentLocale ? 'sand' : 'darkGreen',
                              css: css`
                                text-transform: uppercase;
                              `,
                            }}
                          >
                            {lang}
                          </Button>
                        </GatsbyLink>
                      )
                    },
                  )}
                </Flex>
                <Flex
                  {...{
                    flexDirection: `column`,
                    css: css`
                      flex-grow: 1;
                    `,
                  }}
                >
                  {[
                    {
                      textId: 'practicalInfo',
                      to: '/practical-info/',
                      icon: 'info',
                    },
                    {
                      textId: 'map',
                      to: '/map/',
                      icon: 'map-pin',
                    },
                    {
                      textId: 'favorites',
                      to: '/favorites/',
                      icon: 'star',
                    },
                    {
                      textId: 'aboutTheApp',
                      to: '/about-the-app/',
                      icon: 'help-circle',
                    },
                  ].map(({ textId, to, icon }) => (
                    <Flex
                      {...{
                        key: textId,
                        css: css`
                          text-transform: uppercase;
                        `,
                      }}
                    >
                      <FeatherIcon
                        {...{
                          icon,
                          color: 'darkGreen',
                          size: 3,
                          mr: 1,
                        }}
                      />
                      <GatsbyLink
                        {...{
                          to,
                          onClick: () => {
                            toggle()
                          },

                          my: 3,
                          color: 'darkGreen',
                          fontSize: 2,
                        }}
                      >
                        {t(textId)}
                      </GatsbyLink>
                    </Flex>
                  ))}
                </Flex>
                <Box
                  {...{
                    fontSize: 1,
                    alignSelf: 'center',
                    pb: 3,
                    css: {
                      textAlign: 'center',
                    },
                  }}
                >
                  <Hr
                    {...{
                      my: null,
                      mb: 4,
                      border: '0',
                      borderBottom: 'search',
                      borderColor: 'sand',
                    }}
                  />
                  <Text
                    {...{
                      color: 'sand',
                    }}
                  >
                    Built with &#10084; by
                    <br />
                    <Link
                      {...{
                        href: 'https://www.wanna-play.be/',
                        color: 'sand',
                        fontWeight: 'bold',
                      }}
                    >
                      Wanna Play
                    </Link>{' '}
                    and{' '}
                    <Link
                      {...{
                        href: 'https://www.feedbydesign.com/',
                        color: 'sand',
                        fontWeight: 'bold',
                      }}
                    >
                      Feed by Design
                    </Link>
                  </Text>
                  <Text color="sand">Content by Ludiris ASBL</Text>
                </Box>
              </Flex>
            </Flex>
          </Drawer>
        </React.Fragment>
      )}
    </Translations.Consumer>
  )
}

RightDrawer.propTypes = {}

export default RightDrawer
