const unCapitalize = word => {
  const splitted = word.split('')
  const lowArray = splitted.map(char => char.toLowerCase())
  // splitted[0] = splitted[0].toUpperCase()
  // return splitted.join('')
  return lowArray.join('')
}
const pluralize = (word, lang, plural) => {
  if (plural) {
    return plural
  }
  switch (lang) {
    case 'en':
      return `${word}s`
    case 'fr':
      return `${word}s`
    case 'nl':
      return `${word}en`
    default:
      return `${word}s`
  }
}
const unCapitalizeAndPluralize = (word, { en, fr, nl }) => {
  const plural = pluralize(word)
  const pluralEn = pluralize(en)
  const pluralFr = pluralize(fr)
  const pluralNl = pluralize(nl, 'nl')
  return {
    [word]: {
      en,
      fr,
      nl,
    },
    [plural]: {
      en: pluralEn,
      fr: pluralFr,
      nl: pluralNl,
    },
    [unCapitalize(word)]: {
      en: unCapitalize(en),
      fr: unCapitalize(fr),
      nl: unCapitalize(nl),
    },
    [unCapitalize(plural)]: {
      en: unCapitalize(pluralEn),
      fr: unCapitalize(pluralFr),
      nl: unCapitalize(pluralNl),
    },
  }
}

// const indexBubbles = {
//   exhibitors: '/exhibitors/',
//   animations: '/animations/',
//   tournaments: '/tournaments/',
//   gamesMarket: '/games-market/',
//   protozone: '/protozone/',
// }
// const exhibitorsCategories = {
//   editors: '/exhibitors/editors/',
//   shops: '/exhibitors/shops/',
//   gameLibraries: '/exhibitors/game-libraries/',
//   associations: '/exhibitors/associations/',
//   gamesAndSociety: '/exhibitors/games-and-society/',
//   gamesOfTheWorld: '/exhibitors/games-of-the-world/',
//   distributors: '/exhibitors/distributors/',
// }
// const animationsCategories = {
//   tournaments: '/animations/tournaments/',
//   murderParty: '/animations/murder-party/',
//   rolePlayingGames: '/animations/role-playing-games/',
//   escapeGames: '/animations/escape-games/',
//   liveRolePlayingGames: '/animations/live-role-playing-games/',
//   quidditch: '/animations/quidditch/',
//   lightsaber: '/animations/lightsaber/',
//   workshop: '/animations/workshop/',
//   conference: '/animations/conference/',
//   exposition: '/animations/exposition/',
//   gameTables: '/animations/game-tables/',
//   others: '/animations/others/',
// }

const translations = {
  ...unCapitalizeAndPluralize('Exhibitor', {
    en: 'Exhibitor',
    fr: 'Exposant',
    nl: 'Exhibitor',
  }),
  ...unCapitalizeAndPluralize('exhibitor', {
    en: 'exhibitor',
    fr: 'exposant',
    nl: 'exhibitor',
  }),
}

export default translations
