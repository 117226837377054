/* eslint-disable no-undef, react/prop-types */
import React, { Component } from 'react'
import WindowSizeListener from 'react-window-size-listener'

// const isSSR = typeof window === 'undefined'

class AutoHeight extends Component {
  state = {
    windowWidth: 0,
    height: 0,
  }
  componentDidMount() {
    this.defaultAspectRatio = 1.7778
    // eslint-disable-next-line no-console
    // console.log(isSSR)
  }
  setWindowWidth = windowWidth => {
    const aspectRatio =
      this.props.aspectRatio || this.defaultAspectRatio || 1.7778
    this.setState({ windowWidth, height: windowWidth / aspectRatio })
  }
  render() {
    const { children } = this.props
    return (
      <WindowSizeListener
        {...{
          onResize: ({ windowWidth }) => {
            this.setWindowWidth(windowWidth)
          },
        }}
      >
        {children({
          ...this.props,
          ...this.state,
        })}
      </WindowSizeListener>
    )
  }
}

export default AutoHeight
