// /* eslint-disable */
/* eslint-disable react/no-danger, react/prop-types */

import React from 'react'
import _ from 'lodash'
import styled from '@emotion/styled'
import {
  // flex,
  // alignItems,
  space,
  // width,
  // // color,
  // // heights,
  // height,
  layout,
} from 'styled-system'
// import { themeGet } from '@styled-system/theme-get'
import feather from 'feather-icons'
// import styled from '@emotion/styled'
// import {
//   color as styledColor,
//   space,
//   width as styledWidth,
//   heights,
// } from 'styled-system'
import { theme } from '../../utils'

import moreIcons from './moreIcons'

// const icon = feather.icons['arrow-right'].toSvg({
//   class: 'foo bar',
//   'stroke-width': 1,
//   color: 'red',
// })

// const FeatherIcon = system(
//   {
//     as: 'i',
//     // blacklist: ['is'],
//   },
//   {},
//   'radii',
//   'borders',
// )

// check if it is a personalized icon
const getIconObject = iconName => {
  // const safeName = _.camelCase(iconName)
  const customIcon = moreIcons[iconName]

  if (typeof customIcon !== 'undefined') {
    const placeholderIcon = feather.icons[customIcon.replacement]

    placeholderIcon.name = customIcon.name
    placeholderIcon.contents = customIcon.contents
    placeholderIcon.attrs.viewBox = customIcon.viewBox
    placeholderIcon.tags = null

    return placeholderIcon
  }

  return feather.icons[iconName]
}

const Icon = ({
  icon,
  className,

  name,
  viewBox,
  contents,

  color,
  stroke,
  fill,
  width,
  height,
  size,
  strokeWidth,
  strokeLinecap,
  strokeLinejoin,
  ...props
}) => {
  const themeFill = (theme && theme.colors && theme.colors[fill]) || fill
  const themeColor =
    (theme && theme.colors && theme.colors[color || stroke]) || stroke || color
  const themeFontsize =
    (theme && theme.fontSizes && theme.fontSizes[size]) || size

  const iconObject = getIconObject(icon)

  if (!iconObject) {
    throw new Error(`Icon name "${icon}" is unknown`)
  }

  const svg = iconObject
    // .toString()
    .toSvg({
      class: className,
      [`${fill ? 'fill' : null}`]: themeFill || '',
      [`${color || stroke ? 'stroke' : null}`]: themeColor || '',
      [`${size || width ? 'width' : null}`]: width || themeFontsize || '',
      [`${size || height ? 'height' : null}`]: height || themeFontsize || '',
      [`${strokeWidth ? 'stroke-width' : null}`]: strokeWidth || '',
      [`${strokeLinecap ? 'stroke-linecap' : null}`]: strokeLinecap || '',
      [`${strokeLinejoin ? 'stroke-linejoin' : null}`]: strokeLinejoin || '',
      ...props,
    })

  return (
    <i
      // className={className}
      style={{
        display: `flex`,
        alignItems: 'center',
      }}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}

// const StyledFeatherIcon = styled(FeatherIcon)`
//   // ${styledColor};
//   ${space};
//   ${styledWidth};
//   ${heights};
//   display: flex;
//   align-items: flex-end;
// `

const StyledFeatherIcon = styled(Icon)(
  // () => ({
  //   // display: `flex`,
  //   // TODO: avoid important here
  //   // marginRight: '0!important',
  // }),
  // flex,
  // alignItems,
  space,
  // width,
  // // color,
  // // heights,
  // height,
  layout,
)

StyledFeatherIcon.displayName = 'FeatherIcon'
StyledFeatherIcon.defaultProps = {}

export default StyledFeatherIcon
