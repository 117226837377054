/* eslint-disable no-undef, react/prop-types */
import 'typeface-nunito/index.css'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// import Helmet from 'react-helmet'
import _ from 'lodash'
import { Global, css } from '@emotion/core'
import qs from 'qs'

import { ThemeProvider } from 'emotion-theming'

import { theme, iconsConfig } from '../utils'
import {
  Flex,
  Box,
  Input,
  // Card,
  FeatherIcon,
  Fixed,
  Text,
  Button,
  Hr,
} from '../elements'
import {
  Navigation,
  RightDrawer,
  // SearchField,
  SearchDrawer,
  SEO,
} from '../components'
import {
  DrawerContainer,
  Translations,
  // Search
  SearchContainer,
} from '../containers'
// import CookieAlert from '../containers/CookieAlert'

/* eslint-disable */
import normalize from 'normalize.css'
const injectGlobal = css`
  * {
    font-family: nunito, sans-serif;
    box-sizing: border-box;
  }
  body,
  #___gatsby,
  #___gatsby > div {
    min-height: 100vh;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    cursor: pointer;
  }
  .break {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .show-links a {
    color: ${theme.colors.blue};
    textdecoration: underline;
  }
`
// h1, h2, h3, h4, h5, h6 {
//   color: ${theme.colors.heading};
// };
// p {
//   color: ${theme.colors.text};
// }
/* eslint-enable */

// const FontConfig = styled.div`
//   ${fontSize};
// `

const query = graphql`
  query LayoutQuery {
    logo: file(relativePath: { regex: "logos/LOGO_BGF_2019_V2-2.png/" }) {
      childImageSharp {
        fixed(width: 75, height: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    translations: allTranslation(filter: { identifier: { ne: null } }) {
      nodes {
        identifier
        English
        French
        Dutch
      }
    }
    searchEntries: allAirtable(
      sort: { fields: [data___Start, data___End, data___Name] }
    ) {
      edges {
        node {
          id
          queryName
          # fields {
          #   relativePaths
          #   dirtyString
          #   exhibitor {
          #     defaultName
          #     relativePaths
          #     featuredImage {
          #       id
          #       url
          #       size
          #       thumbnails {
          #         small {
          #           url
          #           width
          #           height
          #         }
          #         large {
          #           url
          #           width
          #           height
          #         }
          #         full {
          #           url
          #           width
          #           height
          #         }
          #       }
          #     }
          #   }
          #   animation {
          #     name {
          #       en
          #       fr
          #       nl
          #     }
          #     relativePaths
          #     days
          #     start
          #     end
          #     categories
          #     featuredImage {
          #       id
          #       url
          #       size
          #       thumbnails {
          #         small {
          #           url
          #           width
          #           height
          #         }
          #         large {
          #           url
          #           width
          #           height
          #         }
          #         full {
          #           url
          #           width
          #           height
          #         }
          #       }
          #     }
          #     boardgames {
          #       id
          #       fields {
          #         boardgame {
          #           featuredImage {
          #             id
          #             url
          #             size
          #             thumbnails {
          #               small {
          #                 url
          #                 width
          #                 height
          #               }
          #               large {
          #                 url
          #                 width
          #                 height
          #               }
          #               full {
          #                 url
          #                 width
          #                 height
          #               }
          #             }
          #           }
          #         }
          #       }
          #     }
          #   }
          #   boardgame {
          #     relativePaths
          #     name {
          #       en
          #       fr
          #       nl
          #     }
          #     description {
          #       en
          #       fr
          #       nl
          #     }
          #     pitch {
          #       en
          #       fr
          #       nl
          #     }
          #     website
          #     facebook
          #     minPlayers
          #     maxPlayers
          #     duration
          #     age
          #     audience
          #     featuredImage {
          #       id
          #       url
          #       size
          #       thumbnails {
          #         small {
          #           url
          #           width
          #           height
          #         }
          #         large {
          #           url
          #           width
          #           height
          #         }
          #         full {
          #           url
          #           width
          #           height
          #         }
          #       }
          #     }
          #   }
          # }
        }
      }
    }
  }
`

class CookieAlert extends React.Component {
  // static propTypes = {
  //   t: PropTypes.func,
  // }
  // static defaultProps = {
  //   t: null,
  // }
  state = {
    cookieAccepted: true,
  }

  componentDidMount() {
    this.checkCookie()
  }

  checkCookie = () => {
    const cookieAccepted = localStorage.getItem('accepts-cookies') === 'true'
    this.setState({ cookieAccepted })
  }

  handleClick = () => {
    localStorage.setItem('accepts-cookies', 'true')
    this.setState({ cookieAccepted: true })
  }

  render() {
    return this.state.cookieAccepted === false ? (
      <Translations.Consumer>
        {({ t }) => (
          <Flex
            {...{
              flexDirection: 'column',
              alignItems: 'center',
              css: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 20,
                // maxHeight: this.state.cookieAccepted === true ? 0 : 200,
                // visibility:
                //   this.state.cookieAccepted === true ? 'hidden' : 'visible',
                // transition: 'max-height 0.5s, visibility 0s linear 0.5s',
              },
            }}
          >
            <Box
              {...{
                bg: 'red',
                color: 'white',
                // width: 3 / 4,
                p: 2,
                mx: 1,
                borderRadius: 2,
                maxWidth: 800,
              }}
            >
              <Text
                {...{
                  fontSize: 1,
                  color: 'white',
                }}
              >
                {t('cookieWarning')}
              </Text>
              <Button
                {...{
                  onClick: this.handleClick,

                  bg: 'red',
                  color: 'white',
                  border: 8,
                  m: null,
                  mt: 2,
                  mx: 'auto',
                  css: {
                    display: 'block!important',
                  },
                }}
              >
                OK
              </Button>
            </Box>
          </Flex>
        )}
      </Translations.Consumer>
    ) : null
  }
}

const { maxWidth } = theme.site

const GatsbyLayout = ({
  children,
  location,
  // ...props
}) => {
  const {
    logo,
    translations: { nodes: translationsData },
    // searchEntries,
  } = useStaticQuery(query)

  const { pathname, search: queryString } = location
  const regex = /^\/en\/|^\/nl\//
  const currentLocale =
    (pathname.match(regex) && pathname.match(regex)[0].substring(1, 3)) || 'fr'
  const basePathname =
    currentLocale === 'fr' ? pathname.substring(1) : pathname.substring(4)
  const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true })

  const translations = _.chain(translationsData)
    .keyBy(e => e.identifier)
    .mapValues(({ English: en, French: fr, Dutch: nl }) => ({ en, fr, nl }))
    .value()
  const t = (word, locale) =>
    translations[word] && translations[word][locale || currentLocale]

  return (
    <ThemeProvider theme={theme}>
      <Translations.Provider
        {...{
          value: {
            translations,
            currentLocale,
            basePathname,
            queryString,
            queryParams,
            t,
          },
        }}
      >
        <DrawerContainer {...{}}>
          {({ open, toggle, windowWidth }) => (
            <SearchContainer {...{}}>
              {({
                input,
                inputChange,
                handleFocus,
                exhibitors,
                animations,
                boardgames,
                guests,
                isFocused,
              }) => {
                const menuBackLayer = open && windowWidth < maxWidth
                const searchBackLayer = !!(isFocused && input)

                return (
                  <React.Fragment>
                    <Global styles={injectGlobal} />
                    <SEO
                      {...{
                        url: true,
                        icon: true,
                        lang: currentLocale,
                        image: true,
                        title: true,
                        // description: true,
                      }}
                    >
                      {/* IOS icons for home screen */}
                      {iconsConfig.ios.map(({ rel, src: href, sizes }) => (
                        <link {...{ key: href, rel, href, sizes }} />
                      ))}
                      {/* IOS Splash screens */}
                      <meta name="apple-mobile-web-app-capable" content="yes" />
                      {iconsConfig.iosScreens.map(
                        ({ rel, src: href, sizes }) => (
                          <link {...{ key: href, rel, href, sizes }} />
                        ),
                      )}
                    </SEO>
                    <Box
                      {...{
                        onClick: () => {
                          toggle(false)
                          handleFocus(false)
                        },

                        bg: 'trans.5',
                        // display: 'none',
                        css: {
                          position: 'fixed',
                          left: menuBackLayer || searchBackLayer ? 0 : '100%',
                          top: 0,
                          bottom: 0,
                          right: 0,
                          zIndex: menuBackLayer ? 9 : 5,
                        },
                      }}
                    />
                    <Flex
                      {...{
                        onClick: () => {
                          handleFocus(false)
                        },

                        // flexGrow: 1,
                        justifyContent: 'center',
                        bg: 'bg',
                        css: css`
                          min-height: 100vh;
                        `,
                      }}
                    >
                      <Flex
                        {...{
                          flexDirection: 'column',
                          flexGrow: 1,
                          minHeight: '100vh',
                          bg: 'white',
                          fontSize: 2,
                          width: '100%',
                          maxWidth: 'fullWidth',
                        }}
                      >
                        <Navigation
                          {...{
                            logo,
                            path: pathname,
                            toggleDrawer: toggle,
                            windowWidth,
                          }}
                        />

                        <Hr
                          {...{
                            my: 0,
                            border: 0,
                            borderBottom: 'search',
                            borderColor: 'sand',
                          }}
                        />
                        <Box
                          {...{
                            // onBlur: () => handleFocus(false),

                            width: '100%',
                            // px: 2,
                            pt: 0,
                            // pb: 4,
                            maxWidth: 600,
                            m: 'auto',
                            css: {
                              position: 'relative',
                              zIndex: 8,
                            },
                          }}
                        >
                          <Box
                            {...{
                              onClick: () => {
                                if (input) {
                                  inputChange('')
                                }
                              },

                              width: 26,
                              css: {
                                position: 'absolute',
                                right: 12,
                                top: 4,
                                cursor: input ? 'pointer' : 'auto',
                              },
                            }}
                          >
                            <FeatherIcon
                              {...{
                                icon: input ? 'x' : 'search',
                                color: 'textAlt',
                                size: 4,
                                // mr: 1,
                              }}
                            />
                          </Box>
                          <Input
                            {...{
                              // disabled: true,
                              // placeholder: t('comingSoon'),
                              value: input,
                              onChange: inputChange,
                              onFocus: () => handleFocus(true),
                              // onBlur: () => handleFocus(false),
                              onClick: e => {
                                e.stopPropagation()
                              },
                              'aria-label': 'search field',
                              boxShadow: 'none',
                              border: 'none',
                              borderLeft: `search`,
                              borderRight: `search`,
                              borderColor: 'sand',
                              borderRadius: 0,
                            }}
                          />
                          <SearchDrawer
                            {...{
                              input,
                              isFocused,
                              exhibitors,
                              animations,
                              boardgames,
                              guests,
                              handleFocus,
                            }}
                          />
                        </Box>

                        {children}
                      </Flex>
                      <RightDrawer {...{ open, toggle, windowWidth }} />
                    </Flex>
                    <CookieAlert />
                  </React.Fragment>
                )
              }}
            </SearchContainer>
          )}
        </DrawerContainer>
      </Translations.Provider>
    </ThemeProvider>
  )
  // {/* <Footer /> */}
}

export default GatsbyLayout
