/* eslint-disable no-undef, react/prop-types */
import React, { useMemo } from 'react'
// import moment from 'moment'
// import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import createAnimation from '../../types/animation'
import {
  Flex,
  Box,
  Image,
  GatsbyLink,
  Text,
  // Badge,
  FeatherIcon,
} from '../../elements'
import { AnimationCategories } from '..'
import { Consumer as TranslationsConsumer } from '../../containers/Translations'
import { FavoriteSelector } from '../../containers'
import { canonize } from '../../utils'

const AnimationEntry = ({
  currentCategory,
  animation: an,
  filterValues,
  onlyFavorites,
}) => {
  const animation = createAnimation(an)

  const categoryLow = currentCategory && canonize(currentCategory, true)
  const {
    // name,
    nameCanonical,
    nameFallback,
    // days,
    // start,
    // end,
    categories,
    relativePath,
    featuredImage,
    boardgames,
    isFilteredBy,
    // fridayIsException,
    schedule: sched,
  } = animation

  const schedule = JSON.parse(sched)
  const scheduleGroups = schedule.groups

  const path = relativePath
  const isNight = categories.find(cat => Boolean(cat.match(/night/)))
  const isTournament = categories.find(cat => Boolean(cat.match(/tournaments/)))
  const boardgameImage = boardgames && boardgames[0].featuredImage
  const mainColor = isTournament ? 'tournament' : 'animation'

  // FILTERING
  if (filterValues) {
    const fOuts = Object.entries(filterValues).map(([key, fValue]) => {
      const fOut = useMemo(() => isFilteredBy(key, fValue), [fValue])
      return fOut
    })

    const display = !fOuts.reduce((acc, val) => acc || val, false)

    if (!display) return null
  }

  return (
    <TranslationsConsumer>
      {({ t, currentLocale }) => (
        <FavoriteSelector
          {...{
            name: `animation.${nameCanonical}`,
          }}
        >
          {({ toggleFavorite, isFavorite }) =>
            (!onlyFavorites || (onlyFavorites && isFavorite)) && (
              <Flex
                {...{
                  width: ['100%', '100%', 1 / 2, 1 / 2],
                  py: 2,
                  px: [2, 2, 3],
                  bg: 'bg',
                  color: 'text',
                }}
              >
                <GatsbyLink.Flex
                  {...{
                    to: path,
                    // onClick,
                    // width: '100%',
                    // maxWidth: 400,
                    // p: 2,
                    flexGrow: 1,
                  }}
                >
                  <Image
                    {...{
                      featuredImage: featuredImage || boardgameImage,
                      // src: featuredImage.thumbnails.large.url,

                      width: [70, 80, 90],
                      height: [70, 80, 90],
                      mr: 2,
                      borderRadius: 'smallFeaturedImage',
                      css: {
                        flexShrink: 0,
                        // ...(isTournament ? { objectFit: `contain` } : null),
                      },
                    }}
                  />
                  <Flex
                    {...{
                      // flexWrap: 'wrap',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Text.box
                      {...{
                        fontSize: 1,
                        // fontSize: [1, 1, 1],
                        fontWeight: 'bold',
                        // width: '100%',
                        color: mainColor,
                        // color: 'text',
                        css: css`
                          text-transform: uppercase;
                        `,
                      }}
                    >
                      {nameFallback[currentLocale]}
                    </Text.box>
                    <Flex
                      {...{
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <FeatherIcon
                        {...{
                          icon: isNight ? 'moon' : 'sun',
                          color: mainColor,
                          fill: mainColor,
                          size: 1,
                          // height: 30,
                          // width: 30,
                          mr: 1,
                          // mb: 1,
                        }}
                      />
                      <Box>
                        {Object.entries(scheduleGroups).map(
                          ([timesString, schedDays]) => {
                            return (
                              <Flex
                                {...{
                                  key: timesString,
                                  justifyContent: 'space-between',
                                  flexWrap: 'wrap',
                                }}
                              >
                                <Text
                                  {...{
                                    // color: 'textAlt',
                                    fontSize: 1,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {`${schedDays
                                    .map(day => t(day).substring(0, 3))
                                    .join(', ')}: `}
                                </Text>
                                <Text
                                  {...{
                                    fontSize: 1,
                                    m: null,
                                    ml: 1,
                                  }}
                                >
                                  {timesString}
                                </Text>
                              </Flex>
                            )
                          },
                        )}
                      </Box>
                    </Flex>
                    <AnimationCategories
                      {...{
                        categories,
                        // color: 'animations',
                        // bg: 'white',
                        color: 'textAlt',
                        fontSize: 0,
                      }}
                    />
                  </Flex>
                </GatsbyLink.Flex>
                <Flex
                  {...{
                    width: 7,
                    flexShrink: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    css: {
                      cursor: 'pointer',
                    },
                    onClick: e => {
                      e.stopPropagation()
                      toggleFavorite()
                    },
                  }}
                >
                  <FeatherIcon
                    {...{
                      icon: 'star',
                      stroke: mainColor,
                      fill: isFavorite ? mainColor : '',
                      size: 3,
                      // mr: 1,
                      // mb: 1,
                    }}
                  />
                </Flex>
              </Flex>
            )
          }
        </FavoriteSelector>
      )}
    </TranslationsConsumer>
  )
}

// AnimationEntry.propTypes = {
//   boardgame: PropTypes.object.isRequired,
// }

export default AnimationEntry
