/* eslint-disable no-undef, react/prop-types */

import React, { useMemo } from 'react'
// import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import createBoardgame from '../../types/boardgame'
import {
  Flex,
  Badge,
  Image,
  Text,
  GatsbyLink,
  FeatherIcon,
  Box,
} from '../../elements'
import { AnimationCategories } from '..'
import { Consumer as TranslationsConsumer } from '../../containers/Translations'
import { FavoriteSelector } from '../../containers'
// import { theme } from '../../utils'

const BoardgamePreview = ({ boardgame: bg, filterValues, onlyFavorites }) => {
  if (!bg) return null

  const boardgame = createBoardgame(bg)

  const {
    relativePath,
    nameCanonical,
    // name,
    nameFallback,
    age,
    audience,
    duration,
    minPlayers,
    maxPlayers,
    featuredImage,
    accessibility,
    proto,
    isFilteredBy,
  } = boardgame

  const path = relativePath
  const players = `${minPlayers}-${maxPlayers}`

  const mainColor = proto ? 'protozone' : 'boardgame'

  const propsCaracteristics = {
    alignItems: 'center',
    mr: 3,
  }
  const propsCaracIcon = {
    stroke: mainColor,
    fill: mainColor,
    size: 1,
    // height: 30,
    // width: 30,
    mr: 1,
    // mb: 1,
  }

  // FILTERING
  if (filterValues) {
    const fOuts = Object.entries(filterValues).map(([key, fValue]) => {
      const fOut = useMemo(() => isFilteredBy(key, fValue), [fValue])
      return fOut
    })

    const display = !fOuts.reduce((acc, val) => acc || val, false)

    if (!display) return null
  }

  return (
    <TranslationsConsumer>
      {({ t, currentLocale }) => (
        <FavoriteSelector
          {...{
            name: `boardgame.${nameCanonical}`,
          }}
        >
          {({ toggleFavorite, isFavorite }) =>
            (!onlyFavorites || (onlyFavorites && isFavorite)) && (
              <Flex
                {...{
                  width: ['100%', '100%', 1 / 2, 1 / 2],
                  py: 2,
                  px: [2, 2, 3],
                  bg: 'bg',
                  color: 'text',
                }}
              >
                <GatsbyLink.Flex
                  {...{
                    to: path,
                    // onClick,

                    // height: 70,
                    // maxWidth: 400,
                    flexGrow: 1,
                  }}
                >
                  <Image
                    {...{
                      featuredImage,
                      imageSize:
                        typeof window !== 'undefined' ? 'large' : 'small',
                      // src: featuredImage.thumbnails.large.url,
                      width: [70, 80, 90],
                      height: [70, 80, 90],
                      mr: 2,
                      borderRadius: 'smallFeaturedImage',
                      css: {
                        flexShrink: 0,
                      },
                    }}
                  />
                  <Flex
                    {...{
                      flexDirection: `column`,
                      justifyContent: ['space-between'],
                      // flexWrap: 'wrap',
                      // alignItems: 'center',
                      // alignContent: 'flex-start',
                      // height: 80,
                    }}
                  >
                    <Text.box
                      {...{
                        fontSize: 1,
                        fontWeight: 'bold',
                        color: mainColor,
                        css: css`
                          text-transform: uppercase;
                        `,
                      }}
                    >
                      {nameFallback[currentLocale]}
                    </Text.box>
                    {/* <Text
              {...{
                fontSize: 0,
                color: 'text',
                // height: 50,
                // height: theme.fontSizes[0] * 3,
                css: css`
                  // flex-grow:1;
                  height: ${theme.fontSizes[0] * 3}px;
                  overflow: hidden;
                `,
              }}
            >
              {pitch[currentLocale] || pitch.fr}
            </Text> */}
                    <Flex
                      {...{
                        // justifyContent: 'space-around',
                        flexWrap: 'wrap',
                      }}
                    >
                      {[
                        { icon: 'age', value: age },
                        { icon: 'players', value: players },
                        {
                          icon: 'chrono',
                          value: `${duration}'`,
                        },
                      ].map(({ icon, value }) => (
                        <Flex
                          {...{
                            ...propsCaracteristics,
                            key: icon,
                          }}
                        >
                          <FeatherIcon
                            {...{
                              icon,
                              ...propsCaracIcon,
                            }}
                          />
                          <Text
                            {...{
                              fontSize: 1,
                              color: mainColor,
                              css: css`
                                flex-shrink: 1;
                              `,
                            }}
                          >
                            {value}
                          </Text>
                        </Flex>
                      ))}
                    </Flex>
                    <Flex {...{ alignItems: 'center' }}>
                      <AnimationCategories
                        {...{
                          categories: [
                            t(audience) || t(audience, 'en'),
                            proto && 'Proto',
                          ],
                          bg: mainColor,
                          color: 'textAlt',
                          fontSize: 0,
                        }}
                      />
                      {accessibility && (
                        <Flex {...{ ml: 2 }}>
                          {accessibility.map(
                            icon =>
                              icon !== 'differentLanguage' && (
                                <FeatherIcon
                                  {...{
                                    key: icon,
                                    icon: `bgf_${icon}`,
                                    mr: 2,
                                    // ...propsCaracIcon,
                                  }}
                                />
                              ),
                          )}
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </GatsbyLink.Flex>
                <Flex
                  {...{
                    width: 7,
                    flexShrink: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    css: {
                      cursor: 'pointer',
                    },
                    onClick: e => {
                      e.stopPropagation()
                      toggleFavorite()
                    },
                  }}
                >
                  <FeatherIcon
                    {...{
                      icon: 'star',
                      stroke: mainColor,
                      fill: isFavorite ? mainColor : '',
                      size: 3,
                      // mr: 1,
                      // mb: 1,
                    }}
                  />
                </Flex>
              </Flex>
            )
          }
        </FavoriteSelector>
      )}
    </TranslationsConsumer>
  )
}

export default BoardgamePreview
