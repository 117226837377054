// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-animation-template-js": () => import("/opt/build/repo/src/templates/animation-template.js" /* webpackChunkName: "component---src-templates-animation-template-js" */),
  "component---src-templates-exhibitor-template-js": () => import("/opt/build/repo/src/templates/exhibitor-template.js" /* webpackChunkName: "component---src-templates-exhibitor-template-js" */),
  "component---src-templates-boardgame-template-js": () => import("/opt/build/repo/src/templates/boardgame-template.js" /* webpackChunkName: "component---src-templates-boardgame-template-js" */),
  "component---src-templates-guest-template-js": () => import("/opt/build/repo/src/templates/guest-template.js" /* webpackChunkName: "component---src-templates-guest-template-js" */),
  "component---src-pages-about-the-app-js": () => import("/opt/build/repo/src/pages/about-the-app.js" /* webpackChunkName: "component---src-pages-about-the-app-js" */),
  "component---src-pages-animations-index-js": () => import("/opt/build/repo/src/pages/animations/index.js" /* webpackChunkName: "component---src-pages-animations-index-js" */),
  "component---src-pages-boardgames-index-js": () => import("/opt/build/repo/src/pages/boardgames/index.js" /* webpackChunkName: "component---src-pages-boardgames-index-js" */),
  "component---src-pages-exhibitors-index-js": () => import("/opt/build/repo/src/pages/exhibitors/index.js" /* webpackChunkName: "component---src-pages-exhibitors-index-js" */),
  "component---src-pages-favorites-index-js": () => import("/opt/build/repo/src/pages/favorites/index.js" /* webpackChunkName: "component---src-pages-favorites-index-js" */),
  "component---src-pages-games-market-js": () => import("/opt/build/repo/src/pages/games-market.js" /* webpackChunkName: "component---src-pages-games-market-js" */),
  "component---src-pages-guests-index-js": () => import("/opt/build/repo/src/pages/guests/index.js" /* webpackChunkName: "component---src-pages-guests-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("/opt/build/repo/src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-practical-info-js": () => import("/opt/build/repo/src/pages/practical-info.js" /* webpackChunkName: "component---src-pages-practical-info-js" */),
  "component---src-pages-protozone-js": () => import("/opt/build/repo/src/pages/protozone.js" /* webpackChunkName: "component---src-pages-protozone-js" */)
}

