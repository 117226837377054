import styled from '@emotion/styled'
import Text from './Text'

const Truncate = styled(Text)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

Truncate.displayName = 'Truncate'

export default Truncate
