/* eslint-disable no-undef, react/prop-types */

import { Component } from 'react'

class FavoriteSelectorContainer extends Component {
  state = {
    isFavorite: false,
  }

  componentDidMount() {
    // this.key = this.props.name
    this.key = `isFav:${this.props.name}`

    // TODO: Check for availability
    // const storageAvailable = type => {
    //   try {
    //     var storage = window[type],
    //       x = '__storage_test__'
    //     storage.setItem(x, x)
    //     storage.removeItem(x)
    //     return true
    //   } catch (e) {
    //     return (
    //       e instanceof DOMException &&
    //       // everything except Firefox
    //       (e.code === 22 ||
    //         // Firefox
    //         e.code === 1014 ||
    //         // test name field too, because code might not be present
    //         // everything except Firefox
    //         e.name === 'QuotaExceededError' ||
    //         // Firefox
    //         e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
    //       // acknowledge QuotaExceededError only if there's something already stored
    //       storage.length !== 0
    //     )
    //   }
    // }

    this.checkFavoriteState()
  }

  getItemFromLocalStorage = () =>
    // localStorage.getItem(`isFav:${this.props.name}`)
    localStorage.getItem(this.key)

  setItemInLocalStorage = () => {
    localStorage.setItem(this.key, true)
    this.setState({ isFavorite: true })
  }

  removeItemFromLocalStorage = () => {
    localStorage.removeItem(this.key)
    this.setState({ isFavorite: false })
  }
  // toggleState = () => {
  //   this.setState({ isFavorite: !this.state.isFavorite })
  // }

  checkFavoriteState = () => {
    if (this.getItemFromLocalStorage()) {
      this.setState({ isFavorite: true })
    } else {
      this.setState({ isFavorite: false })
    }
  }

  toggleFavorite = () => {
    if (this.getItemFromLocalStorage()) {
      this.removeItemFromLocalStorage()
    } else {
      this.setItemInLocalStorage()
    }
  }

  render() {
    const { children } = this.props
    return children({
      ...this.state,
      toggleFavorite: this.toggleFavorite,
    })
  }
}

export default FavoriteSelectorContainer
