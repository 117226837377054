import styled from '@emotion/styled'
import Button from './Button'

export const ButtonTransparent = styled(Button)({
  bg: 'transparent',
  color: 'inherit',
})

ButtonTransparent.displayName = 'ButtonTransparent'

export default ButtonTransparent
