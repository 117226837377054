/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import { Flex, Badge } from '../../elements'
import { Consumer as TranslationsConsumer } from '../../containers/Translations'

const Badges = ({
  list,
  flex,
  badge,
  child,
  color,
  bg,
  fontSize,
  container,
  ...props
}) => (
  <TranslationsConsumer>
    {({ t }) =>
      list &&
      !!list.length && (
        <Flex
          {...{
            flexWrap: 'wrap',
            // ...(container && {
            //   px: [2, 2, 3],
            //   // py: [2, 2, 3],
            // }),
            ...flex,
            ...props,
          }}
        >
          {list.map(listProps => {
            const {
              val,
              color: c,
              bg: backG,
              borderColor,
              toggleSelect,
            } = listProps

            return val && !child ? (
              <Badge
                {...{
                  key: val,
                  borderRadius: 3,
                  mr: 1,
                  my: 1,
                  p: null,
                  px: 2,
                  py: '2px',
                  // mr: 1,
                  color: c || 'textAlt',
                  bg: backG || 'inherit',
                  border: 1,
                  borderColor: borderColor || 'textAlt',
                  ...badge,
                  children: val,
                  onClick: toggleSelect || null,
                }}
              />
            ) : (
              child(listProps)
            )
          })}
        </Flex>
      )
    }
  </TranslationsConsumer>
)

export default Badges
