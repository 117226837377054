import { useState, useEffect } from 'react'

// function useLocalStorage(key, initialValue) {
//   // State to store our value
//   // Pass initial state function to useState so logic is only executed once
//   const [storedValue, setStoredValue] = useState(() => {
//     try {
//       // Get from local storage by key
//       const item = window.localStorage.getItem(key)
//       // Parse stored json or if none return initialValue
//       return item ? JSON.parse(item) : initialValue
//     } catch (error) {
//       // If error also return initialValue
//       console.log(error)
//       return initialValue
//     }
//   })

//   // Return a wrapped version of useState's setter function that ...
//   // ... persists the new value to localStorage.
//   const setValue = value => {
//     try {
//       // Allow value to be a function so we have same API as useState
//       const valueToStore =
//         value instanceof Function ? value(storedValue) : value
//       // Save state
//       setStoredValue(valueToStore)
//       // Save to local storage
//       window.localStorage.setItem(key, JSON.stringify(valueToStore))
//     } catch (error) {
//       // A more advanced implementation would handle the error case
//       console.log(error)
//     }
//   }

//   return [storedValue, setValue]
// }

const localStorage = typeof window !== 'undefined' && window.localStorage

const getItem = key => {
  if (localStorage) {
    const storedValue = localStorage.getItem(key)
    return JSON.parse(storedValue)
  }
  return null
}

const setItem = (key, value) => {
  if (localStorage) {
    localStorage.setItem(key, JSON.stringify(value))
  }
  return null
}

const useLocalStorage = (key, initialValue) => {
  const [state, setState] = useState(() => {
    const storedValue = getItem(key)
    if (storedValue) {
      return storedValue
    }
    return initialValue
  })

  // useEffect(() => {
  //   const storedValue = getItem(key)
  //   if (storedValue) {
  //     setState(storedValue)
  //   }
  // }, [])

  useEffect(() => {
    setItem(key, state)
  }, [state])

  return [state, setState]
}

export default useLocalStorage
